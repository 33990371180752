/* eslint-disable no-underscore-dangle */
import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import {
  ImageList,
  ImageListItem,
  Theme,
  useMediaQuery,
  IconButton,
  Card,
  CardActionArea,
  CardContent,
  Box,
  Link,
  Typography,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useWindowSize from 'src/hooks/useWindowSize';
import useSession from 'src/hooks/useSession';
import { Link as RouterLink } from 'react-router-dom';
import { Branche } from 'src/types/branche';

const splitName = (name: string, onlyFirst: boolean, sep: string = ' ') => {
  if (name) {
    const result = name.split(sep);

    if (!onlyFirst) {
      result.shift();
      return result.join(sep);
    }

    return result[0];
  }

  return '';
};

interface CategoriesSliderProps {
  listBranches: Branche[]
}

const CategoriesSlider: FC<CategoriesSliderProps> = (props) => {
  const { listBranches } = props;

  const { session, saveSession } = useSession();
  const ref = useRef(null);
  const windowSize = useWindowSize();
  const breakpointSXOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const [rightPos, setRightPos] = useState(0);
  const [showNavArrows, setShowNavArrows] = useState(true);

  const scrollStep = 400;

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const handleCategoryClick = (checkedKey: string) => {
    const sessionData = session;
    sessionData.selectedSearchMode = checkedKey;
    saveSession({
      ...session
    });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (ref) {
      const contentWidth = ref.current.clientWidth + ref.current.offsetLeft - 52;
      setRightPos(contentWidth);
      setShowNavArrows(ref.current.clientWidth < ref.current.scrollWidth);
    }
  }, [windowSize]);

  if (!listBranches) {
    return null;
  }

  return (
    <>
      <ImageList
        sx={{ flexWrap: 'nowrap',
          display: 'flex',
          flexDirection: 'row',
          scrollBehavior: 'smooth'
        }}
        ref={ref}
      >
        {!breakpointSXOnly && showNavArrows && (
        <Box sx={{ position: 'absolute', zIndex: 1020, marginTop: 15 }}>
          <IconButton
            sx={{
              width: 50,
              height: 50,
              background: (theme: Theme) => theme.palette.secondary.main,
              opacity: 0.8,
              '&:hover': {
                backgroundColor: (theme: Theme) => theme.palette.primary.main,
                opacity: 1
              }
            }}
            onClick={() => scroll(-scrollStep)}
          >
            &nbsp;
            <ArrowBackIosIcon sx={{ color: '#fff' }} />
          </IconButton>
        </Box>
        )}
        {listBranches.map((branch) => (
          <Link
            key={branch.id}
            color="textSecondary"
            component={RouterLink}
            to="/"
            underline="none"
            variant="body2"
          >
            <ImageListItem
              key={branch.id}
            >
              <Card
                sx={{ boxShadow: 'none',
                  // backgroundColor: (theme: KumaoTheme) => theme.palette.background.mintgreen
                }}
              >
                <CardActionArea>
                  <CardContent
                    onClick={() => handleCategoryClick(branch.name)}
                  >
                    <Box sx={{ height: 240, backgroundColor: branch.color, borderRadius: 5, width: 165, marginLeft: 5, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;;' }}>
                      <Typography
                        gutterBottom
                        variant={branch?.smallFont ? 'subtitle1' : 'h5'}
                        sx={{ paddingLeft: 2, paddingTop: 2, color: 'white', position: 'relative', zIndex: 1, textShadow: '1px 1px #777a7b;', fontWeight: 'bold' }}
                      >
                        {splitName(branch.name, true)}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant={branch?.smallFont ? 'subtitle1' : 'h5'}
                        sx={{ paddingLeft: `${branch.name2pl * 8}px`, marginTop: -1, color: 'white', position: 'relative', zIndex: 1, textShadow: '1px 1px #777a7b;', fontWeight: 'bold' }}
                      >
                        {splitName(branch.name, false)}
                      </Typography>
                    </Box>
                    <Box
                      component="img"
                      sx={{
                        top: branch.top,
                        left: branch.left,
                        height: 200,
                        position: 'absolute',
                        transform: `rotate(${branch.rotate}deg);`,
                      }}
                      src={branch.picture}
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
              )
            </ImageListItem>
          </Link>
        ))}
        {!breakpointSXOnly && showNavArrows && (
          <Box sx={{ position: 'absolute', zIndex: 1020, marginTop: 15, left: rightPos }}>
            <IconButton
              sx={{
                width: 50,
                height: 50,
                background: (theme: Theme) => theme.palette.secondary.main,
                opacity: 0.8,
                '&:hover': {
                  backgroundColor: (theme: Theme) => theme.palette.primary.main,
                  opacity: 1
                }
              }}
              onClick={() => scroll(scrollStep)}
            >
              <ArrowForwardIosIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Box>
        )}
      </ImageList>
    </>
  );
};

export default CategoriesSlider;
