import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material/styles';
// import useSettings from 'src/hooks/useSettings';
import Footer from './Footer';
import MainNavbar from './MainNavbar';
import MainSidebar from './MainSidebar';
// import CookieDialog from './widgets/overlays/CookieDialog';

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayoutRoot = experimentalStyled('div')(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      height: '100%',
      paddingTop: 64
    }
  )
);

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  // const { settings } = useSettings();
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  // const [isCookieDialogOpen, setIsCookieDialogOpen] = useState<boolean>(!settings?.cookieSettings?.requiredAccepted);

  // const handleCookieDialogClose = () => {
  //  setIsCookieDialogOpen(false);
  // };

  return (
    <MainLayoutRoot>
      <MainNavbar
        onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
      />
      <MainSidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      {children || <Outlet />}
      {/* disabled because we do not use cookies at the moment
      <CookieDialog
        open={isCookieDialogOpen}
        onClose={handleCookieDialogClose}
      />
      */}
      <Footer />
    </MainLayoutRoot>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
