import { UserRole } from 'src/api/dto/user/userRole';

const useHasAccess = (minimumRole: UserRole, currentRoles: UserRole[]) => {
  // Check for current Role and all Roles above. For Future: maybe there is something like IComparable
  // console.log('UserRoles:');
  // console.log(currentRoles);
  const grantedRoles: UserRole[] = [UserRole.Anonymous];
  currentRoles.forEach((role) => {
    switch (role) {
      case UserRole.User:
        grantedRoles.push(UserRole.User);
        break;
      case UserRole.BusinessUser:
        grantedRoles.push(UserRole.User);
        grantedRoles.push(UserRole.BusinessUser);
        break;
      case UserRole.BusinessManager:
        grantedRoles.push(UserRole.User);
        grantedRoles.push(UserRole.BusinessUser);
        grantedRoles.push(UserRole.BusinessManager);

        break;
      case UserRole.BusinessAdmin:
        grantedRoles.push(UserRole.User);
        grantedRoles.push(UserRole.BusinessUser);
        grantedRoles.push(UserRole.BusinessManager);
        grantedRoles.push(UserRole.BusinessAdmin);
        break;
      case UserRole.Admin:
        grantedRoles.push(UserRole.User);
        grantedRoles.push(UserRole.BusinessUser);
        grantedRoles.push(UserRole.BusinessManager);
        grantedRoles.push(UserRole.BusinessAdmin);
        grantedRoles.push(UserRole.Admin);
        break;
      default:
        break;
    }
  });
  return grantedRoles.includes(minimumRole);
};

export default useHasAccess;
