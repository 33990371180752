import { Box, Container, Grid, Typography, Theme, useMediaQuery } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FC, ReactElement } from 'react';
import { SelectedService } from 'src/types/selectedService';
import ServiceBookingItem from './ServiceBookingItem';
import ServiceBookingItemAccordion from './ServiceBookingItemAccordion';

interface ServiceBookingProps {
  organizationNr: number;
  onSelectedSlot(slot: Date):void;
  colorCode: string;
  selectedId: Number;
  sessionServices: SelectedService[];
  onSelect(id: Number):void;
}

const ServiceBooking: FC<ServiceBookingProps> = (props) => {
  const { organizationNr, onSelectedSlot, colorCode, selectedId, sessionServices, onSelect } = props;
  const hiddenMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  if (!sessionServices || sessionServices.length === 0) { return (<Typography sx={{ fontWeight: 700 }}>Noch keinen Service ausgewählt</Typography>); }

  const ServiceBookingDesktop = (): ReactElement => (
    <Container>
      {sessionServices.map((_service) => (
        <Grid
          key={_service.id}
          container
        >
          <Grid
            item
            xs={11}
            sx={{ mb: 2 }}
          >
            <ServiceBookingItem
              sessionService={_service}
              colorCode={colorCode}
              selected={selectedId === _service.id}
              id={_service.id}
              onSelect={onSelect}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ mb: 2 }}
            alignContent="center"
          >
            {selectedId === _service.id && (
            <Box
              width="100%"
              height="100%"
              display="flex"
            >
              <ArrowForwardIosIcon
                fontSize="large"
                color="primary"
                sx={{ margin: 'auto' }}
              />
            </Box>
            )}
          </Grid>
        </Grid>
      ))}
    </Container>
  );

  const ServiceBookingMobile = (): ReactElement => (
    <Container
      sx={{ p: 0, width: '100%' }}
      disableGutters
    >
      {sessionServices.map((_service) => (
        <ServiceBookingItemAccordion
          organizationNr={organizationNr}
          onSelectedSlot={onSelectedSlot}
          sessionService={_service}
          colorCode={colorCode}
          selected={selectedId === _service.id}
          id={_service.id}
          onSelect={onSelect}
          key={_service.id}
          sessionServices={sessionServices}
        />
      ))}
    </Container>
  );

  return (
    <>
      {!hiddenMdDown ? (<ServiceBookingDesktop />) : (<ServiceBookingMobile />)}
    </>
  );
};

export default ServiceBooking;
