import { createContext, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import axios from 'src/lib/axios';
import { SelectedService } from 'src/types/selectedService';
import { UserDataFields } from 'src/components/booking/confirmation/UserDataFields';
import { OfferedServiceDTO } from 'src/api/dto/organization/offeredService';
import { BookingUserDTO } from 'src/api/dto/booking/bookingUser';
import { CustomizedBookingFieldsDTO } from 'src/api/dto/booking/customizedBookingFields';
import { UserDataCustomizedFields } from 'src/components/booking/customizedFields/UserDataCustomizedFields';

interface Session {
  selectedServices: SelectedService[];
  userData: UserDataFields;
  lastViewedOrganizations: number[];
  selectedSearchMode: string;
  searchTerm: string;
  isLoading: boolean;
  userDataCustomizedFields?: UserDataCustomizedFields[];
}

export interface SessionContextValue {
  session: Session;
  saveSession: (update: Session) => void;
  addService: (currentSession: Session, service: OfferedServiceDTO, user: BookingUserDTO) => void;
  updateServices: (organizationNr: Number) => void;
  updateSelectedServices: (organizationNr: Number) => void;
}

interface SessionProviderProps {
  children?: ReactNode;
}

const initialSettings: Session = {
  selectedServices: [],
  userData: null,
  lastViewedOrganizations: [],
  selectedSearchMode: 'Alle',
  searchTerm: '',
  isLoading: false,
  userDataCustomizedFields: null,
};

/**
 * DateTime reviver to store Date object in local storage
 *
 * @param {*} key
 * @param {*} value
 * @return {*}
 */
const dateTimeReviver = (key, value) => {
  let a;
  if (typeof value === 'string') {
    a = /^(?:\d{4})-(?:\d{2})-(?:\d{2})T(?:\d{2}):(?:\d{2}):(?:\d{2}(?:\.\d*)?)(?:(?:-(?:\d{2}):(?:\d{2})|Z)?)$/.exec(value);
    if (a) {
      return new Date(value);
    }
  }
  return value;
};

export const restoreSession = (): Session | null => {
  let session = null;

  try {
    const storedData: string | null = window.localStorage.getItem('session');
    // const storedData: string | null = sessionStorage.getItem('session');

    if (storedData) {
      session = JSON.parse(storedData, dateTimeReviver);
    } else {
      session = initialSettings;
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return session;
};

export const storeSession = (session: Session): void => {
  window.localStorage.setItem('session', JSON.stringify(session));
  // sessionStorage.setItem('session', JSON.stringify(session));
};

const SessionContext = createContext<SessionContextValue>({
  session: initialSettings,
  saveSession: () => {},
  addService: () => {},
  updateServices: () => {},
  updateSelectedServices: () => {}
});

export const SessionProvider: FC<SessionProviderProps> = (props) => {
  const { children } = props;
  const [session, setSession] = useState<Session>(initialSettings);

  useEffect(() => {
    const restoredSession = restoreSession();

    if (restoredSession) {
      setSession(restoredSession);
    }
  }, []);

  const saveSession = (updatedSession: Session): void => {
    setSession(updatedSession);
    storeSession(updatedSession);
  };

  const addService = (currentSession: Session, service: OfferedServiceDTO, user: BookingUserDTO): void => {
    let id = Math.max.apply(null, (currentSession.selectedServices.map((n) => n.id)));
    if (!id || id < 0) { id = 0; }
    currentSession.selectedServices.push({
      service,
      organizationNr: service.organizationNr,
      quantity: 1,
      dateAdded: new Date(),
      user,
      id: id + 1,
      selectedSlot: null
    });
    saveSession({
      ...currentSession
    });
  };

  /**
   * Updates the services with current prices, textes in the session
   *
   * @param {Number} organizationNr
   */
  const updateServices = async (organizationNr: Number) => {
    if (session && session.selectedServices.length > 0) {
      try {
        const response = await axios.get<OfferedServiceDTO[]>(
          '/api/offeredservices', { params: { organizationNr } }
        );
        session.selectedServices.filter((item) => item.organizationNr === organizationNr)
          .forEach(async (x) => {
            x.service = response.data.find((item) => item.serviceID === x.service.serviceID);

            const customizedBookingFields = await axios.get<CustomizedBookingFieldsDTO[]>(
              '/api/customizedbookingfields', { params: { serviceid: x.service.serviceID } }
            );

            if (customizedBookingFields.data) {
              x.customizedBookingFields = customizedBookingFields.data;
            }
          });
      } catch (err) {
        console.log('API Error start');
        console.error(err);
        console.log('API Error End');
      }
    }
  };

  const updateSelectedServices = (organizationNr: Number) => {
    if (session && session.selectedServices.length > 0) {
      session.selectedServices.filter((item) => item.organizationNr === organizationNr)
        .forEach((x) => {
          if (x.selectedSlot <= new Date(Date.now())) {
            x.selectedSlot = null;
          }
        });
    }
  };

  return (
    <SessionContext.Provider
      value={{
        session,
        saveSession,
        addService,
        updateServices,
        updateSelectedServices
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const SessionConsumer = SessionContext.Consumer;

export default SessionContext;
