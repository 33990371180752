import type { FC } from 'react';
import { useMediaQuery, Theme, Box, IconButton } from '@mui/material';
import useSession from 'src/hooks/useSession';

interface LogoProps {
  responsive?: boolean
}

const Logo: FC<LogoProps> = (props) => {
  const hiddenSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { session, saveSession } = useSession();
  const { responsive } = props;
  let width = '200';
  if (hiddenSmDown && responsive) {
    width = '110';
  }

  const handleOnClickLogo = () => {
    window.scrollTo(0, 0);
    session.searchTerm = '';
    session.selectedSearchMode = 'Alle';
    saveSession({
      ...session
    });
  };

  return (
    <Box>
      <IconButton onClick={handleOnClickLogo}>
        <img
          alt="Logo"
          src="/static/logo.png"
          width={width}
        />
      </IconButton>
    </Box>
  );
};

export default Logo;
