class DateHelper {
  weekDayName = [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag'
  ];

  weekDayNameShort = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];

  monthNames = [
    'Jänner',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ];

  getMonthName(date: Date) {
    return this.monthNames[date.getMonth()];
  }

  getWeekDayName(day: number): string;
  getWeekDayName(date: Date): string;
  getWeekDayName(dayOrDate: any): string {
    if (typeof dayOrDate === 'number') {
      return this.weekDayName[dayOrDate - 1];
    }
    const dNum = (dayOrDate.getDay() + 6) % 7; // javascript starts with sunday so we have to shift it
    return this.weekDayName[dNum];
  }

  getWeekDayNameShort(day: number): string;
  getWeekDayNameShort(date: Date): string;
  getWeekDayNameShort(dayOrDate: any): string {
    if (typeof dayOrDate === 'number') {
      return this.weekDayNameShort[dayOrDate - 1]; // array starts with 0 so substract 1
    }
    const dNum = (dayOrDate.getDay() + 6) % 7; // javascript starts with sunday so we have to shift it
    return this.weekDayNameShort[dNum];
  }

  getDateFromNow = (days: number, hour: number, minute: number) => {
    const result = new Date();
    result.setDate(result.getDate() + days);
    result.setHours(hour, minute, 0, 0);
    return result;
  };

  addToDateFromNow = (days: number, hours: number, minutes: number) => {
    const result = new Date();
    result.setDate(result.getDate() + days);
    result.setHours(result.getHours() + hours);
    result.setMinutes(result.getMinutes() + minutes);
    return result;
  };

  getNextMonth = (now: Date) => {
    if (now.getMonth() === 11) {
      return new Date(now.getFullYear() + 1, 0, 1);
    }
    return new Date(now.getFullYear(), now.getMonth() + 1, 1);
  };

  toISOLocalDateTime = (date: Date) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    date.toLocaleString('sv').replace(' ', 'T');

  removeTimezoneAndOffset = (date: Date) => {
    const result = new Date();
    result.setDate(date.getDate());
    result.setMonth(date.getMonth());
    result.setFullYear(date.getFullYear());
    result.setHours(date.getHours());
    result.setMinutes(date.getMinutes());
    result.setSeconds(date.getSeconds());
    result.setMilliseconds(date.getMilliseconds());
    return result;
  };

  formatMinutes = (minutes: number) => {
    let text = `${minutes} Minuten`;

    if (minutes > 90) {
      let fHours = Math.floor(minutes / 60);
      const fMinutes = Math.floor(minutes % 60);
      const fDays = Math.floor(fHours / 24);

      text = '';
      if (fDays > 0) {
        text += `${fDays} Tag`;
        if (fDays > 1) {
          text += 'e';
        }

        fHours = Math.floor(fHours % 24);
      }

      if (fHours > 0) {
        text += ` ${fHours} Stunde`;
        if (fHours > 1) {
          text += 'n';
        }
      }

      if (minutes > 0 && fDays === 0) {
        text += ` ${fMinutes} Minute`;
        if (minutes > 1) {
          text += 'n';
        }
      }
    }

    return text;
  };
}

// Singleton
const dateHelper = new DateHelper();

export default dateHelper;
