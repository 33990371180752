import { FC, Fragment } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Divider,
  Avatar,
  Chip
} from '@mui/material';
import { OrganizationDTO } from 'src/api/dto/organization/organization';
import { SelectedService } from 'src/types/selectedService';
import { BookingUserDTO } from 'src/api/dto/booking/bookingUser';
import { serverConfig } from 'src/config';
import { KumaoTheme } from 'src/theme';
import zeroPad from 'src/utils/zeroPad';

interface SummaryProps {
  organization: OrganizationDTO;
  selectedServices: SelectedService[];
  colorCode: string;
  onShowSelection?: () => void;
}

const Summary: FC<SummaryProps> = (props) => {
  const { organization, selectedServices, colorCode, onShowSelection } = props;
  // const getSelectedServiceDuration = () => selectedServices.map((a) => a.service.duration).reduce((accumulator, current) => accumulator + current, 0);
  const getSelectedServiceAmount = () => selectedServices.map((a) => a.service.price).reduce((accumulator, current) => accumulator + current, 0);
  const getSelectedUserJSX = (user: BookingUserDTO) => {
    if (user === undefined) {
      return null;
    }
    return (
      <Avatar
        src={user.avatar ? serverConfig.baseIMGUrl + user.avatar : ''}
        sx={{
          height: 32,
          width: 32,
          marginTop: 0.1,
          bgcolor: user.avatar ? '' : (theme: KumaoTheme) => theme.palette.primary.light
        }}
      >
        {!user.avatar && (
        <Typography
          color="textPrimary"
          fontWeight={700}
        >
          {user.firstName && user.firstName.length > 0 ? user.firstName[0].toUpperCase() : ''}
          {user.lastName && user.lastName.length > 0 ? user.lastName[0].toUpperCase() : ''}
        </Typography>
        )}
      </Avatar>
    );
  };

  return (
    <Container sx={{ pt: 3, pb: 3, pl: 1, pr: 1, borderRadius: '10px', border: '1px solid #979797' }}>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          style={{ textAlign: 'center' }}
          sx={{ pb: 2 }}
          xs={12}
        >
          <Typography
            color={colorCode}
            sx={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: 15, pb: 1 }}
          >
            {organization.companyName}
          </Typography>
          <Typography sx={{ fontFamily: 'Roboto' }}>
            {organization.streetName}
            {' '}
            {organization.streetNumber}
            ,
          </Typography>
          <Typography sx={{ fontFamily: 'Roboto' }}>
            {organization.zipCode}
            {' '}
            {organization.city}
          </Typography>
        </Grid>
        {selectedServices.map((service) => (
          <Fragment key={service.id}>
            <Grid
              item
              style={{ textAlign: 'center' }}
              xs={12}
            >
              <Divider>
                <Chip
                  label={service.service.description}
                  sx={{ fontWeight: 'bold' }}
                />
              </Divider>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ textAlign: 'right' }}
            >
              <Typography sx={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: 40 }}>
                {zeroPad(service.selectedSlot.getHours(), 2)}
                :
                {zeroPad(service.selectedSlot.getMinutes(), 2)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Typography sx={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: 19 }}>{service.selectedSlot.toLocaleDateString()}</Typography>
              <Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>
                Gesamtzeit:
                {' '}
                {service.service.duration}
                {' '}
                min
              </Typography>
            </Grid>
            <Grid
              item
              style={{ textAlign: 'center' }}
              xs={12}
            >
              <Typography
                sx={{ fontFamily: 'Roboto' }}
              >
                {`Preis: ${service.service.price} EUR`}
              </Typography>
            </Grid>
            {service.user && (
            <>
              <Grid
                item
                style={{ textAlign: 'center' }}
                xs={12}
              >
                <Divider
                  variant="inset"
                  textAlign="right"
                />
              </Grid>
              <Grid
                item
                style={{ textAlign: 'center' }}
                xs={12}
              >
                <Typography sx={{ fontFamily: 'Roboto' }}>Sie werden betreut von</Typography>
              </Grid>

              <Grid
                item
                style={{ textAlign: 'center', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}
                xs={12}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {getSelectedUserJSX(service.user)}
                </Box>
              </Grid>
              <Grid
                item
                style={{ textAlign: 'center' }}
                xs={12}
              >
                {service.user?.firstName}
              </Grid>
            </>
            )}
            <Grid
              item
              sx={{ pb: 2 }}
            />
          </Fragment>
        ))}

        <Grid
          item
          style={{ textAlign: 'center' }}
          xs={12}
        >
          <Divider />
        </Grid>
        {onShowSelection && (
        <Grid
          item
          style={{ textAlign: 'center' }}
          xs={12}
        >
          <Button
            color="primary"
            size="large"
            sx={{ borderRadius: '20px', borderWidth: '2px', fontFamily: 'Roboto' }}
            variant="outlined"
            onClick={onShowSelection}
          >
            Slots ändern
          </Button>
        </Grid>
        )}
        <Grid
          item
          style={{ textAlign: 'center' }}
          xs={12}
        >
          <Typography sx={{ fontFamily: 'Roboto', fontWeight: 700 }}>Gesamtsumme</Typography>
          <Typography sx={{ fontFamily: 'Roboto', fontWeight: 700 }}>
            {getSelectedServiceAmount()}
            {' '}
            €
          </Typography>
          <Typography sx={{ fontFamily: 'Roboto', pt: 3 }}>Bezahlung erfolgt vor Ort</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Summary;
