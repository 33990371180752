import { Button, Container, Grid, Typography, Theme, useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CalendarBookingslotsOverviewDTO } from 'src/api/dto/organization/calendarBookingslotsOverview';
import DateHelper from 'src/utils/dateHelper';
import zeroPad from 'src/utils/zeroPad';

interface ServiceBookingSlotsProps {
  organizationId: number;
  slots: CalendarBookingslotsOverviewDTO[];
  onSelectedSlotChanged: (selectedSlot:string) => void;
  selectedDate?: Date;
  nextAvailableSlot: CalendarBookingslotsOverviewDTO;
}

const ServiceBookingSlots: FC<ServiceBookingSlotsProps> = (props) => {
  const { slots, onSelectedSlotChanged, selectedDate, nextAvailableSlot } = props;
  const [selectedSlot, setselectedSlot] = useState<string>('');
  const hiddenMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const handleButtonClick = (dateFrom:string) => {
    setselectedSlot(dateFrom);
    onSelectedSlotChanged(dateFrom);
  };

  useEffect(() => {
    if (selectedDate && selectedDate !== undefined) { setselectedSlot(DateHelper.toISOLocalDateTime(selectedDate)); }
  }, [selectedDate]);

  const NextAvailableAppointment = () => {
    if (nextAvailableSlot) {
      return (
        <Typography sx={{ fontWeight: 700, p: 1 }}>
          Keine Termine an dem Tag verfügbar (Nächster verfügbarer Termin am
          {' '}
          {new Date(nextAvailableSlot.dateFrom).toLocaleDateString()}
          )
        </Typography>
      );
    }
    return (<Typography sx={{ fontWeight: 700, p: 1 }}>Es tut uns Leid. Derzeit sind keine freien Termine verfügbar.</Typography>);
  };

  if (!slots || slots.length === 0) { return (<NextAvailableAppointment />); }

  return (
    <Container>
      <Grid
        container
        spacing={1}
        sx={{ pt: 1 }}
      >
        {slots.map((item) => (
          <Grid
            key={item.dateFrom}
            item
            xs={3}
          >
            <Button
              color={selectedSlot === item.dateFrom ? 'primary' : 'secondary'}
              size={hiddenMdDown ? 'small' : 'medium'}
              sx={{ borderRadius: '20px', borderWidth: '1px', fontFamily: 'Roboto', borderColor: '#748AA1', minWidth: 60 }}
              variant={selectedSlot === item.dateFrom ? 'contained' : 'outlined'}
              onClick={() => handleButtonClick(item.dateFrom)}
              data-cy={`slot${item.dateFrom.replace(':', '_')}`}
            >
              <Typography
                sx={{ fontWeight: 'normal', fontFamily: 'Roboto', fontSize: '16px' }}
              >
                {`${zeroPad(new Date(item.dateFrom).getHours(), 2)}:${zeroPad(new Date(item.dateFrom).getMinutes(), 2)}`}
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ServiceBookingSlots;
