import { Box, Grid, Skeleton, TextField, Typography, Theme, useMediaQuery } from '@mui/material';
import axios from 'src/lib/axios';
import { FC, useCallback, useEffect, useState } from 'react';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import DateHelper from 'src/utils/dateHelper';
import { CalendarBookingslotsOverviewDTO } from 'src/api/dto/organization/calendarBookingslotsOverview';
import ServiceBookingSlots from 'src/components/booking/selection/ServiceBookingSlots';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import atLocale from 'date-fns/locale/de-AT';
import { SelectedService } from 'src/types/selectedService';
// import { PickersDay, PickersDayProps, pickersDayClasses } from '@mui/lab';

interface BookingSlotPickerProps {
  organizationNr: number;
  service: SelectedService;
  selectedDate?: Date;
  sessionServices: SelectedService[];
  onSelectedSlot: (selectedSlot: Date) => void;
}

const BookingSlotPicker: FC<BookingSlotPickerProps> = (props) => {
  const { organizationNr, service, selectedDate, sessionServices, onSelectedSlot } = props;
  const [bookableDays, setBookableDays] = useState(new Array<String>());
  const [bookingSlots, setbookingSlots] = useState(new Array<CalendarBookingslotsOverviewDTO>());
  const [filteredBookingSlots, setFilteredBookingSlots] = useState(new Array<CalendarBookingslotsOverviewDTO>());
  const [nextAvailableSlot, setNextAvailableSlot] = useState<CalendarBookingslotsOverviewDTO>(null);
  const [selectedDay, setSelectedDay] = useState<Date | null>(new Date());
  const [selectedSlot, setSelectedSlot] = useState<Date | null>(null);
  const [maxDate] = useState<Date>(DateHelper.getDateFromNow(180, 23, 59));
  const isMountedRef = useIsMountedRef();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const hiddenMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const shouldDisableCalendarDate = (currentDay: Date) => !bookableDays.includes(currentDay.toDateString());

  const createBookableDays = () => {
    const days = [];
    bookingSlots.forEach((item) => {
      const myDate = new Date(item.dateFrom);
      days.push(myDate.toDateString());
    });
    setBookableDays(days);
    return days;
  };

  const onSelectedSlotChanged = (date:string) => {
    setSelectedSlot(new Date(date));
  };

  const getEndDateofSelectedService = (svc: SelectedService) : Date => {
    if (svc.selectedSlot) {
      const endDate = new Date(svc.selectedSlot);
      endDate.setMinutes(svc.selectedSlot.getMinutes() + svc.service.duration);
      return endDate;
    }
    return null;
  };

  const filterAlreadyChoosenSlots = (slots: CalendarBookingslotsOverviewDTO[]) : CalendarBookingslotsOverviewDTO[] => {
    const usedSlots = Array<{ start: Date; end: Date; count: number }>();
    sessionServices.forEach((svc) => {
      // dont delete the own selection
      if (service && svc.id !== service.id && svc.selectedSlot) {
        if (svc.user) {
          slots.filter((sl) => sl.dateFrom >= DateHelper.toISOLocalDateTime(svc.selectedSlot) && sl.dateFrom < DateHelper.toISOLocalDateTime(getEndDateofSelectedService(svc))).forEach((item) => {
            const index = slots.findIndex((sl) => sl.dateFrom === item.dateFrom);
            if (index >= 0) {
              slots[index].userIdList = slots[index].userIdList.filter((id) => id !== +svc.user.userId);
              if (slots[index].userIdList.length === 0) {
                slots.splice(index, 1);
              }
            }
          });
        } else {
          // If no user is selected we have to count how often it was choosen
          const index = usedSlots.findIndex((item) => item.start === svc.selectedSlot);
          if (index >= 0) {
            usedSlots[index].count += 1;
          } else {
            usedSlots.push({ start: svc.selectedSlot, end: getEndDateofSelectedService(svc), count: 1 });
          }
        }
      }
    });
    // check if enough employees are given, if the slot can be selected
    for (let i = 0; i < usedSlots.length; i++) {
      slots.filter((sl) => sl.dateFrom >= DateHelper.toISOLocalDateTime(usedSlots[i].start) && sl.dateFrom < DateHelper.toISOLocalDateTime(usedSlots[i].end)).forEach((item) => {
        const index = slots.findIndex((sl) => sl.dateFrom === item.dateFrom);
        if (index >= 0) {
          if (slots[index].userIdList.length <= usedSlots[i].count) {
            slots.splice(index, 1);
          }
        }
      });
    }
    return slots;
  };

  const searchAppointments = useCallback(async (selectedServices: SelectedService[]) => {
    try {
      if (selectedServices.length === 0) { return; }
      const serviceIds = [];
      selectedServices.map((item) => serviceIds.push(item.service.serviceID));
      const response = await axios.post<CalendarBookingslotsOverviewDTO[]>(
        '/api/calendar/getbookingslotsoverview', {
          organizationNr,
          serviceIdList: serviceIds,
          dateFrom: DateHelper.toISOLocalDateTime(new Date()),
          dateTo: DateHelper.toISOLocalDateTime(maxDate)
        }
      );
      if (isMountedRef.current) {
        setbookingSlots(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const checkIfSelectedDayHasSlots = (day:Date, availableDays:String[]) => availableDays.includes(day.toDateString());

  useEffect(() => {
    if (selectedDate) {
      setSelectedDay(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (!service) { return; }
    const { user } = service;
    const mySlots = filterAlreadyChoosenSlots(bookingSlots);
    if (mySlots.length > 0) {
      setNextAvailableSlot(mySlots[0]);
    } else {
      setNextAvailableSlot(null);
    }
    if (user === undefined || user === null) {
      setFilteredBookingSlots(mySlots.filter((item) => new Date(item.dateFrom).toDateString() === selectedDay.toDateString()));
    } else {
      setFilteredBookingSlots(mySlots.filter(
        (item) => item.userIdList.includes(+user.userId)
      ).filter(
        (item) => new Date(item.dateFrom).toDateString() === selectedDay.toDateString()
      ));
    }
  }, [bookingSlots, service, selectedDay]);

  useEffect(() => {
    const days = createBookableDays();
    setIsLoading(false);
    // remove if we decide to
    if (days.length > 0 && !checkIfSelectedDayHasSlots(selectedDay, days)) {
      setSelectedDay(new Date(days[0]));
    }
  }, [bookingSlots]);

  useEffect(() => {
    if (service) {
      setIsLoading(true);
      searchAppointments([service]);
    }
  }, [service]);

  useEffect(() => {
    if (selectedSlot) { onSelectedSlot(selectedSlot); }
  }, [selectedSlot]);

  // const renderCalendarDay = (day: Date, selectedDays: Array<Date | null>, pickersDayProps: PickersDayProps<Date>): JSX.Element => {
  //  return (
  //   <PickersDay
  //    {...pickersDayProps}
  //    sx={{
  //      color:'red'
  //    }}
  //  ><Box>{day.getDate()}</Box></PickersDay>
  //  );
  // };

  if (!service) return null;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%' }}
    >
      <Grid container>
        <Grid
          xs={12}
          item
          alignContent="center"
          alignItems="center"
        >
          <Typography
            align="center"
            sx={{ color: '#676767', fontSize: hiddenMdDown ? 16 : 20, fontWeight: 700, fontFamily: 'Rubik' }}
          >
            Datum und Uhrzeit auswählen
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          alignContent="center"
        >
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={atLocale}
          >
            <Box sx={{
              border: hiddenMdDown ? '' : '1px solid #979797',
              borderRadius: hiddenMdDown ? 0 : 5,
              p: hiddenMdDown ? 0 : 1 }}
            >
              <StaticDatePicker
                disablePast
                minDate={new Date()}
                maxDate={maxDate}
                displayStaticWrapperAs="desktop"
                openTo="day"
                value={selectedDay}
                      // showDaysOutsideCurrentMonth
                onChange={(newValue) => {
                  setSelectedDay(newValue);
                }}
                      // renderDay={renderCalendarDay}
                shouldDisableDate={shouldDisableCalendarDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          alignContent="center"
          sx={{ mt: hiddenMdDown ? 0 : 2 }}
        >
          {!isLoading
            ? (
              <>
                <Typography>Bitte einen Termin auswählen:</Typography>
                <ServiceBookingSlots
                  organizationId={organizationNr}
                  onSelectedSlotChanged={onSelectedSlotChanged}
                  slots={filteredBookingSlots}
                  selectedDate={selectedDate}
                  nextAvailableSlot={nextAvailableSlot}
                />
              </>
            )
            : <Skeleton height="50px" />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookingSlotPicker;
