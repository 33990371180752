import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import GuestGuard from './components/GuestGuard';
import AuthGuard from './components/AuthGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import { UserRole } from './api/dto/user/userRole';
import Booking from './pages/booking/Booking';
import BookingSuccess from './pages/booking/BookingSuccess';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// TEST pages

// const Test = Loadable(lazy(() => import('./pages/Test')));

// Common pages

const TermsConditions = Loadable(lazy(() => import('./pages/commons/TermsConditions')));
const ImpDataPriv = Loadable(lazy(() => import('./pages/commons/ImpDataPriv')));
const RegisterSuccessful = Loadable(lazy(() => import('./pages/commons/RegisterSuccessful')));
const Help = Loadable(lazy(() => import('./pages/commons/Help')));
const MediaOverview = Loadable(lazy(() => import('./pages/commons/MediaOverview')));

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const PasswordChange = Loadable(lazy(() => import('./pages/authentication/PasswordChange')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const EmailVerification = Loadable(lazy(() => import('./pages/authentication/EmailVerification')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Backend pages

const Profile = Loadable(lazy(() => import('./pages/profile/Profile')));
const OrganizationCalender = Loadable(lazy(() => import('./pages/organization/OrganizationCalender')));
const Account = Loadable(lazy(() => import('./pages/Account')));
const Favorites = Loadable(lazy(() => import('./pages/favorites/Favorites')));

// Main pages

const Home = Loadable(lazy(() => import('./pages/Home')));
const Pricing = Loadable(lazy(() => import('./pages/pricing/Pricing')));

// Admin pages
const Batcave = Loadable(lazy(() => import('./pages/admin/Admin')));

const routes: RouteObject [] = [
  {
    path: '',
    caseSensitive: false,
    element: <MainLayout />,
    children: [
      {
        caseSensitive: false,
        path: '/',
        element: <Home />
      },
      {
        path: '/terms-conditions',
        caseSensitive: false,
        element: <TermsConditions />
      },
      {
        path: '/imprint-dataprivacy',
        caseSensitive: false,
        element: <ImpDataPriv />
      },
      {
        path: '/media',
        caseSensitive: false,
        element: <MediaOverview />
      },
      {
        path: '/help',
        caseSensitive: false,
        element: <Help />
      },
      {
        path: '/pricing',
        caseSensitive: false,
        element: <Pricing />
      },
      {
        path: '/login',
        caseSensitive: false,
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: '/register',
        caseSensitive: false,
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: '/register-successful',
        caseSensitive: false,
        element: (
          <RegisterSuccessful />
        )
      },
      {
        path: '/lost-password',
        caseSensitive: false,
        element: (
          <GuestGuard>
            <PasswordReset />
          </GuestGuard>
        )
      },
      {
        path: '/verification',
        caseSensitive: false,
        element: (
          <EmailVerification />
        )
      },
      {
        path: '/reset-password',
        caseSensitive: false,
        element: (
          <GuestGuard>
            <PasswordChange />
          </GuestGuard>
        )
      },
      {
        path: '/organization/:organizationId',
        caseSensitive: false,
        element: (
          <Profile />
        )
      },
      {
        path: '/mycalender',
        caseSensitive: false,
        element: (
          <AuthGuard minimumUserRole={UserRole.BusinessUser}>
            <OrganizationCalender />
          </AuthGuard>
        )
      },
      {
        path: '/account',
        caseSensitive: false,
        element: (
          <AuthGuard minimumUserRole={UserRole.User}>
            <Account />
          </AuthGuard>
        )
      },
      {
        path: '/invitation',
        caseSensitive: false,
        element: (
          <AuthGuard minimumUserRole={UserRole.User}>
            <Account tabName="invitations" />
          </AuthGuard>
        )
      },
      {
        path: '/favorites',
        caseSensitive: false,
        element: (
          <AuthGuard minimumUserRole={UserRole.User}>
            <Favorites />
          </AuthGuard>
        )
      },
      /* { // TODO remove for production
        path: '/widgettest',
        caseSensitive: false,
        element: (
          <AuthGuard minimumUserRole={UserRole.Admin}>
            <Test />
          </AuthGuard>
        )
      }, */
      {
        path: '/batcave',
        caseSensitive: false,
        element: ( // security is in backend
          <AuthGuard minimumUserRole={UserRole.BusinessUser}>
            <Batcave />
          </AuthGuard>
        )
      },
      {
        path: '401',
        caseSensitive: false,
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        caseSensitive: false,
        element: <NotFound />
      },
      {
        path: '500',
        caseSensitive: false,
        element: <ServerError />
      },
      {
        // Workaround because the regex below does not work
        path: '/:organizationId',
        caseSensitive: false,
        element: <Profile />
      },
      // Works on https://forbeslindesay.github.io/express-route-tester/ but not in our environment. maybe we get it working
      /* {
        path: '/:name([A-Za-z0-9-]+)_:organizationId',
        caseSensitive: false,
        element: <Organization />
      }, */
      {
        // Workaround because the regex above does not work
        path: '/:organizationId/booking',
        caseSensitive: false,
        element: <Booking />
      },
      {
        path: '/booking/success',
        caseSensitive: false,
        element: <BookingSuccess />
      },
      {
        path: '*',
        caseSensitive: false,
        element: <NotFound />
      }
    ]
  }
];

export default routes;
