import axios from 'axios';
import qs from 'query-string';
import * as config from 'src/config';

const axiosInstance = axios.create({
  baseURL: config.serverConfig.baseAPIUrl,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'none' })
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

// eslint-disable-next-line operator-linebreak
axiosInstance.defaults.headers['Content-Type'] =
  'application/json;charset=utf-8';

axiosInstance.defaults.headers['Cache-Control'] = 'no-cache';
axiosInstance.defaults.headers.Pragma = 'no-cache';
axiosInstance.defaults.headers.Expires = '0';

export default axiosInstance;
