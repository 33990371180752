import type { FC } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { KumaoTheme } from 'src/theme';

const SupplierBenefits: FC = () => (
  <Card
    color="primary"
    sx={{
      flexGrow: 1,
      overflow: 'hidden',
      px: 3,
      pt: 1,
      backgroundColor: (theme: KumaoTheme) => theme.palette.secondary.light,
      borderRadius: 5
    }}
  >
    <Typography
      sx={{
        color: (theme: KumaoTheme) => theme.palette.secondary.contrastText, fontSize: 22, fontWeight: 700
      }}
    >
      Willkommen werter Dienstleister...
    </Typography>
    <Box sx={{ my: 1, mx: 'auto', p: 2 }}>
      <Grid
        container
        wrap="nowrap"
        spacing={2}
      >
        <Grid item>
          <CheckCircleIcon
            sx={{
              color: (theme: KumaoTheme) => theme.palette.secondary.contrastText,
              fontSize: '50px'
            }}
          />
        </Grid>
        <Grid
          item
          xs
          zeroMinWidth
          sx={{ my: 'auto' }}
        >
          <Typography
            sx={{
              color: (theme: KumaoTheme) => theme.palette.secondary.contrastText, fontSize: 18, fontWeight: 700
            }}
          >
            Du hast allerhand zu tun deine Termine zu organisieren? Hängst nur am Telefon?
            Kumao hilft dir, Termine mit Leichtigkeit zu regeln!
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <Box
      sx={{
        my: 1,
        mx: 'auto',
        p: 2
      }}
    >
      <Grid
        container
        wrap="nowrap"
        spacing={2}
      >
        <Grid item>
          <CheckCircleIcon
            sx={{
              color: (theme: KumaoTheme) => theme.palette.secondary.contrastText,
              fontSize: '50px'
            }}
          />
        </Grid>
        <Grid
          item
          xs
          sx={{ my: 'auto' }}
        >
          <Typography
            sx={{
              color: (theme: KumaoTheme) => theme.palette.secondary.contrastText, fontSize: 18, fontWeight: 700
            }}
          >
            Werde gefunden, präsentiere dein Unternehmen, binde Kunden an dich!
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <Box
      sx={{
        my: 1,
        mx: 'auto',
        p: 2,
      }}
    >
      <Grid
        container
        wrap="nowrap"
        spacing={2}
      >
        <Grid item>
          <CheckCircleIcon
            sx={{
              color: (theme: KumaoTheme) => theme.palette.secondary.contrastText,
              fontSize: '50px'
            }}
          />
        </Grid>
        <Grid
          item
          xs
          sx={{ my: 'auto' }}
        >
          <Typography
            sx={{
              color: (theme: KumaoTheme) => theme.palette.secondary.contrastText, fontSize: 18, fontWeight: 700
            }}
          >
            Gebuchte Termine und freie Slots immer im Überblick!
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </Card>
);

export default SupplierBenefits;
