import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PeopleIcon from '@mui/icons-material/People';
import MessageIcon from '@mui/icons-material/Message';
import { IconMap } from 'src/types/iconMap';
import EventNoteIcon from '@mui/icons-material/EventNote';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import axios from 'src/lib/axios';
import { UserNotificationDTO } from 'src/api/dto/user/userNotification';
import { notificationsConfig } from 'src/config';
import { Link as RouterLink } from 'react-router-dom';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import CloseIcon from '@mui/icons-material/Close';
import WebIcon from '@mui/icons-material/Web';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ReceiptIcon from '@mui/icons-material/Receipt';
import EventIcon from '@mui/icons-material/Event';

const iconsMap: IconMap = {
  Information: MessageIcon,
  Invitation: PeopleIcon,
  Appointment: EventNoteIcon,
  Add: AnnouncementIcon,
  Welcome: EmojiPeopleIcon,
  License: WebIcon,
  Service: LocalOfferIcon,
  Invoice: ReceiptIcon,
  Event: EventIcon,
};

const NotificationsPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<UserNotificationDTO[]>([]);
  const isMountedRef = useIsMountedRef();
  const [notificationPulls, setNotificationPulls] = useState(0);

  const getNotifications = useCallback(async () => {
    try {
      const response = await axios.get<UserNotificationDTO[]>(
        '/api/notifications'
      );
      if (isMountedRef.current) {
        setNotifications(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getNotifications();

    const interval = setInterval(() => {
      setNotificationPulls(notificationPulls + 1);
    }, notificationsConfig.pullInterval);
    return () => clearInterval(interval);
  }, [getNotifications, notificationPulls]);

  const markNotificationRead = async (notification: UserNotificationDTO) => {
    try {
      await axios.post('/api/notificationsetread', notification);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteNotification = async (notification: UserNotificationDTO) => {
    try {
      await axios.delete(`/api/notification/${notification.id}`);
    } catch (err) {
      console.error(err);
    }
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleSelectNotfication = async (notification: UserNotificationDTO) => {
    if (!notification.messageRead) {
      notification.messageRead = true;

      await markNotificationRead(notification);

      const newNotifications = notifications.slice(0);
      setNotifications(newNotifications);
    }
  };

  const handleReadAll = () => {
    notifications.filter((x) => !x.messageRead).forEach((item) => {
      item.messageRead = true;
      markNotificationRead(item);
    });

    const newNotifications = notifications.slice(0);
    setNotifications(newNotifications);
  };

  const handleDeleteNotification = async (notficiation: UserNotificationDTO) => {
    await deleteNotification(notficiation);

    const newNotifications = notifications.filter((x) => x.id !== notficiation.id);
    setNotifications(newNotifications);
  };

  const unreadNotifications = notifications.filter((x) => !x.messageRead).length;

  return (
    <>
      <Tooltip title="Deine Benachrichtigungen">
        <IconButton
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
        >
          <Badge
            color="error"
            badgeContent={unreadNotifications}
          >
            {unreadNotifications === 0 && (<NotificationsNoneIcon />)}
            {unreadNotifications !== 0 && (<NotificationsIcon />)}
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        onClose={handleClose}
        open={open}
        slotProps={{
          paper: { sx: { width: 320 } }
        }}
        disableScrollLock
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            Deine Benachrichtigungen
          </Typography>
        </Box>
        {
          notifications.length === 0
            ? (
              <Box sx={{ p: 2 }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Es sind derzeit keine Benachrichtigungen vorhanden
                </Typography>
              </Box>
            )
            : (
              <>
                <List disablePadding>
                  {notifications.map((notification) => {
                    const Icon = iconsMap[notification.messageIcon];
                    return (
                      <ListItem
                        divider
                        key={notification.id}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              backgroundColor: 'primary.main',
                              color: 'primary.contrastText'
                            }}
                          >
                            <Icon fontSize="small" />
                          </Avatar>
                        </ListItemAvatar>
                        <Link
                          color="textPrimary"
                          component={RouterLink}
                          to={notification.redirectUrl}
                          sx={{ cursor: 'pointer' }}
                          underline="none"
                          variant="subtitle2"
                          onClick={() => handleSelectNotfication(notification)}
                        >
                          <ListItemText
                            primary={(!notification.messageRead && (
                              <Typography variant="h6">
                                {notification.messageTitle}
                              </Typography>
                            )) || (
                            <Typography>
                              {notification.messageTitle}
                            </Typography>
                            )}
                            secondary={notification.message}
                          />
                        </Link>
                        <Box sx={{ position: 'absolute', right: 0, top: 8, paddingRight: 1 }}>
                          <IconButton onClick={() => handleDeleteNotification(notification)}>
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </ListItem>
                    );
                  })}
                </List>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1
                  }}
                >
                  <Button
                    color="primary"
                    size="small"
                    variant="text"
                    onClick={handleReadAll}
                  >
                    Alle als gelesen markieren
                  </Button>
                </Box>
              </>
            )
        }
      </Popover>
    </>
  );
};

export default NotificationsPopover;
