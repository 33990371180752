import { useEffect } from 'react';
import type { FC } from 'react';
import {
  Grid,
  Button,
  Typography,
  Box,
  Theme,
  useMediaQuery,
  Paper
} from '@mui/material';
import { OrganizationDTO } from 'src/api/dto/organization/organization';
import { SelectedService } from 'src/types/selectedService';
import { OfferedEventDTO } from 'src/api/dto/organization/offeredEvent';
import { useNavigate } from 'react-router-dom';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import * as config from 'src/config';
import DoneIcon from '@mui/icons-material/Done';
import Summary from './confirmation/Summary';
import SummaryEvent from './confirmation/SummaryEvent';

interface BookingSuccessDetailsProps {
  colorCode: string;
  organization: OrganizationDTO;
  selectedServices: SelectedService[];
  selectedEvent: OfferedEventDTO;
  icsEntry: string;
}

const BookingSuccessDetails: FC<BookingSuccessDetailsProps> = (props) => {
  const { selectedServices, selectedEvent, colorCode, organization, icsEntry } = props;
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const hiddenMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    if (icsEntry === '') { navigate('/'); }
  }, [isMountedRef]);

  return (
    <>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={8}
          md={7}
          xs={12}
        >
          <Paper
            sx={{ pt: 6, pb: 6, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', boxShadow: '0px -5px 18px rgba(0, 0, 0, 0.1)', }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <DoneIcon
                color="success"
                sx={{ fontSize: 56 }}
              />
              <Typography sx={{ fontFamily: 'Poppins', fontSize: 36, fontWeight: 700, mt: 3 }}>Dein Termin ist fixiert!</Typography>
              <Typography sx={{ fontFamily: 'Roboto', fontSize: 20, fontWeight: 700, }}>In Kürze bekommst du eine Buchungsbestätigung per Mail</Typography>
              <Box sx={{ pt: 2, px: 2 }}>
                <Button
                  color="primary"
                  size="large"
                  sx={{ borderRadius: '20px', borderWidth: '2px', fontFamily: 'Roboto', backgroundColor: '#FFFFFF', mt: 2, mb: 2, '&:hover': { backgroundColor: '#FFF' } }}
                  variant="outlined"
                  href={`${config.serverConfig.baseAPIUrl}/api/calendar/mycalendarentry/download/${icsEntry}`}
                  fullWidth={hiddenMdDown}
                >
                  Termin herunterladen
                </Button>
                {!hiddenMdDown && (
                  <Button
                    color="primary"
                    size="large"
                    sx={{ borderRadius: '20px', borderWidth: '2px', fontFamily: 'Roboto', backgroundColor: '#FFFFFF', mt: 2, mb: 2, ml: 2, '&:hover': { backgroundColor: '#FFF' } }}
                    variant="outlined"
                    href="/"
                  >
                    Zur Startseite
                  </Button>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid
          item
          lg={4}
          md={5}
          xs={12}
        >
          {selectedServices && selectedServices.length > 0 && (
          <Summary
            organization={organization}
            selectedServices={selectedServices}
            colorCode={colorCode}
          />
          )}
          {selectedEvent && (
          <SummaryEvent
            organization={organization}
            selectedEvent={selectedEvent}
            colorCode={colorCode}
          />
          )}
        </Grid>
      </Grid>
      {hiddenMdDown && (
      <Box sx={{ textAlign: 'center', pt: 2 }}>
        <Button
          color="primary"
          size="large"
          sx={{ borderRadius: '20px', borderWidth: '2px', fontFamily: 'Roboto', backgroundColor: '#FFFFFF', mt: 2, mb: 2, '&:hover': { backgroundColor: '#FFF' } }}
          variant="outlined"
          href="/"
          fullWidth
        >
          Zur Startseite
        </Button>
      </Box>
      )}
    </>
  );
};

export default BookingSuccessDetails;
