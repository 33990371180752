/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import { branches } from 'src/dummydata/branches';
import { BrancheListDTO } from 'src/api/dto/organization/brancheList';

const findBranchColor = (orgBranches: BrancheListDTO[]) => {
  if (orgBranches) {
    const selectedBranch = branches.find((x) =>
      orgBranches.some((x2) => x2.id === x.id)
    );

    if (selectedBranch) {
      return selectedBranch.color;
    }
  }

  return '#000000';
};

export default findBranchColor;
