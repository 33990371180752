import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import axios from 'src/lib/axios';
import type { UserDTO } from '../api/dto/user/user';
import { UserRegisterDTO } from '../api/dto/user/userRegister';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: UserDTO | null;
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  impersonate: (email: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (serRegisterDTO: UserRegisterDTO) => Promise<void>;
  initialize: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: UserDTO | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: UserDTO;
  };
};

type ImpersonateAction = {
  type: 'IMPERSONATE';
  payload: {
    user: UserDTO;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: UserDTO;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | ImpersonateAction
  | LogoutAction
  | RegisterAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  IMPERSONATE: (state: State, action: ImpersonateAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  impersonate: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  initialize: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async (): Promise<void> => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken) {
        setSession(accessToken);

        const response = await axios.get<{ userDTO: UserDTO }>('/api/account/me');
        const { userDTO } = response.data;

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: userDTO
          }
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const response = await axios.post<{ accessToken: string; userDTO: UserDTO }>('/api/account/login', {
      email,
      password
    });
    const { accessToken, userDTO } = response.data;

    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: userDTO
      }
    });
  };

  const logout = async (): Promise<void> => {
    window.localStorage.setItem('accessToken', '');
    window.localStorage.setItem('session', '');
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const impersonate = async (email: string): Promise<void> => {
    const response = await axios.post<{ accessToken: string; userDTO: UserDTO }>('/api/admin/impersonate', {
      email,
      password: ''
    });
    await logout();
    const { accessToken, userDTO } = response.data;
    console.group('Impersonate');
    console.log(response.data);
    console.log(window.localStorage.getItem('accessToken'));
    console.groupEnd();
    setSession(accessToken);
    dispatch({
      type: 'IMPERSONATE',
      payload: {
        user: userDTO
      }
    });
  };

  const register = async (
    userRegisterDTO: UserRegisterDTO
  ): Promise<void> => {
    const response = await axios.post<{ accessToken: string; userDTO: UserDTO }>(
      '/api/account/register',
      userRegisterDTO
    );
    const { accessToken, userDTO } = response.data;
    setSession(accessToken);
    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user: userDTO
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        impersonate,
        logout,
        register,
        initialize
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
