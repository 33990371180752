import type { FC } from 'react';
import {
  Button,
  Link
} from '@mui/material';
import { KumaoTheme } from 'src/theme';

const KumaoATkumao: FC = () => (

  <Button
    color="secondary"
    size="small"
    variant="contained"
  >
    <Link
      sx={{
        color: (theme: KumaoTheme) => theme.palette.secondary.contrastText
      }}
      component="a"
      href="mailto:kumao@kumao.at"
      target="_blank"
      rel="noopener noreferrer"
      fontWeight="bold"
    >
      kumao@kumao.at
    </Link>
  </Button>

);

export default KumaoATkumao;
