import React, {
  FC,
  useState
} from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Button
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import KumaoATkumao from './emailaddress/KumaoATkumao';
import MinusIcon from '../icons/Minus';
import Logo from './Logo';

interface IContactKumaoATkumao {}

const sections = [
  {
    title: 'Los gehts!',
    links: [
      {
        title: 'Anmelden',
        href: '/login',
        target: '_self'
      },
      {
        title: 'Jetzt mitmachen',
        href: '/register',
        target: '_self'
      },
      {
        title: 'Preise',
        href: '/pricing',
        target: '_self'
      },
      {
        title: 'Hilfe',
        href: '/help',
        target: '_self'
      }
    ]
  },
  {
    title: 'Infos & Medien',
    links: [
      {
        title: 'Geschäftsbedingungen (AGB)',
        href: '/terms-conditions',
        target: '_self'
      },
      {
        title: 'Impressum & Datenschutz',
        href: '/imprint-dataprivacy',
        target: '_self'
      },
      {
        title: 'Medien und Werbung',
        href: '/media',
        target: '_self'
      }
    ]
  },
  {
    title: 'Social',
    links: [
      {
        title: 'Facebook',
        href: 'https://www.facebook.com/Kumao-104937725591679',
        target: '_blank',
        icon: 'facebook'
      },
      {
        title: 'Instagram',
        href: 'https://www.instagram.com/kumao_at/',
        target: '_blank',
        icon: 'instagram'
      },
      {
        title: 'Youtube',
        href: 'https://www.youtube.com/@kumao_at',
        target: '_blank',
        icon: 'youtube'
      },
    ]
  }
];

const Footer: FC<IContactKumaoATkumao> = () => {
  const [showingEmail, setShowingEmail] = useState(false);

  const email = showingEmail ? (
    <KumaoATkumao />
  ) : (
    <Button
      onClick={() => setShowingEmail(true)}
      color="secondary"
      size="small"
      variant="contained"
    >
      Kontakt E-Mail
    </Button>
  );

  const GetIcon = (value: { icon:string }) => {
    const { icon } = value;
    if (icon === 'facebook') {
      return (<FacebookIcon color="primary" />);
    }
    if (icon === 'instagram') {
      return (<InstagramIcon color="primary" />);
    }
    if (icon === 'youtube') {
      return (<YouTubeIcon color="primary" />);
    }
    return (<MinusIcon color="primary" />);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#F5F5F7', // 'background.default',
        pb: 6,
        pt: {
          md: 10,
          xs: 2
        },
        mt: 5
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={3}
            sm={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              order: {
                md: 1,
                xs: 4
              }
            }}
            xs={12}
          >
            <Logo />
            <Box sx={{ mt: 1 }}>
              <Typography
                color="textSecondary"
                variant="caption"
              >
                <React.Suspense fallback={<div>loading...</div>}>
                  <Box
                    sx={{
                      ml: 3
                    }}
                  >
                    {email}
                  </Box>
                </React.Suspense>
              </Typography>
            </Box>
          </Grid>
          {sections.map((section, index) => (
            <Grid
              item
              key={section.title}
              md={3}
              sm={4}
              sx={{
                order: {
                  md: index + 2,
                  xs: index + 1
                }
              }}
              xs={12}
            >
              <Typography
                color="textSecondary"
                variant="overline"
              >
                {section.title}
              </Typography>
              <List disablePadding>
                {section.links.map((link) => (
                  <ListItem
                    disableGutters
                    key={link.title}
                    sx={{
                      pb: 0,
                      pt: 1
                    }}
                  >
                    <ListItemAvatar
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        minWidth: 0,
                        mr: 0.5
                      }}
                    >
                      <GetIcon icon={link.icon} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={(
                        <Link
                          href={link.href}
                          color="textPrimary"
                          variant="subtitle2"
                          target={link.target}
                        >
                          {link.title}
                        </Link>
                      )}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}
        </Grid>
        <Divider
          sx={{
            borderColor: (theme) => alpha(theme.palette.primary.contrastText, 0.12),
            my: 6
          }}
        />
        <Typography
          color="textSecondary"
          variant="caption"
        >
          All Rights Reserved by Kumao GmbH.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
