import type { FC, HTMLAttributeAnchorTarget } from 'react';
import {
  Avatar,
  Button,
  Link,
  Typography
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import CheckIcon from '../../../icons/Check';
import DialogBase from './DialogBase';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  text: string;
  buttonText?: string;
  link?: string;
  linkTarget?: HTMLAttributeAnchorTarget;
  showIcon?: boolean;
  showCloseIcon?: boolean;
}

const SuccessOneButton: FC<SimpleDialogProps> = (props) => {
  const { onClose, open, title, text, buttonText, link, showIcon, showCloseIcon, linkTarget } = props;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <DialogBase
      onClose={handleClose}
      open={open}
      showCloseIcon={showCloseIcon}
    >
      {showIcon && (
      <Avatar
        sx={{
          backgroundColor: (theme) => alpha(theme.palette.success.main, 0.08),
          color: 'success.main',
          mb: 2
        }}
      >
        <CheckIcon />
      </Avatar>
      )}
      <Typography
        color="#fff"
        variant="h5"
      >
        {title}
      </Typography>
      <Typography
        align="center"
        color="#fff"
        sx={{ mt: 1 }}
        variant="h6"
      >
        {text}
      </Typography>

      <Link
        color="primary"
        component={RouterLink}
        to={link ?? ''}
        target={linkTarget}
      >
        <Button
          fullWidth
          size="large"
          onClick={handleClose}
          variant="outlined"
          color="primary"
          sx={{ minWidth: 100, borderRadius: 20, borderWidth: 2, backgroundColor: '#FFFFFF', mt: 4, mb: 2, '&:hover': { borderWidth: 2, backgroundColor: '#efefed' } }}
        >
          {buttonText}
        </Button>
      </Link>
    </DialogBase>
  );
};

SuccessOneButton.defaultProps = {
  buttonText: 'Ok',
  showIcon: true,
};

export default SuccessOneButton;
