import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Typography, Stack, Collapse, Skeleton } from '@mui/material';
import type { Theme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import axios from 'src/lib/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { BusinessHoursDTO } from 'src/api/dto/organization/businessHours';
import { OrganizationDTO } from 'src/api/dto/organization/organization';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DateHelper from '../../../utils/dateHelper';
import OpeningHours from './OpeningHours';
import ExpandMore from '../expands/ExpandMore';

const toDate = (dateStr: string) => new Date(`${DateHelper.toISOLocalDateTime(new Date()).split('T')[0]} ${dateStr}`);
const formatTime = (dateStr: string) => toDate(dateStr).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
const isTimeBetween = (now: Date, dateFrom: Date, dateTo: Date) => {
  if (now.getTime() >= dateFrom.getTime() && now.getTime() <= dateTo.getTime()) return true;

  return false;
};
const findOpenSlot = (slots: BusinessHoursDTO[]) => {
  if (slots) {
    return slots.find((x) => x.day === (new Date().getDay() === 0 ? 7 : new Date().getDay()) && isTimeBetween(new Date(), toDate(x.openTime), toDate(x.closeTime)) && !x.closed);
  }

  return null;
};

const findNextOpenSlot = (slots: BusinessHoursDTO[]) => {
  const availableSlots = slots.filter((x) => x.openTime !== x.closeTime);
  if (availableSlots) {
    let dayIdx = new Date().getDay();
    let slot: BusinessHoursDTO;

    if (dayIdx === 0) {
      dayIdx = 7;
    }

    let i = dayIdx;
    do {
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      const possibleSlots = availableSlots.filter((x) => x.day === i && !x.closed);
      if (i === dayIdx) {
        const possibleSlot = possibleSlots.find((x) => toDate(x.openTime).getTime() >= new Date().getTime());
        if (possibleSlot) {
          slot = possibleSlot;
        } else if (availableSlots.findIndex((x) => x.day !== dayIdx && !x.closed) === -1) {
          slot = possibleSlots.shift();
        }
      } else if (possibleSlots.length > 0) {
        slot = possibleSlots.shift();
      }

      i++;
      if (i > 7) {
        i = 1;
      }
    } while (!slot && i !== dayIdx);

    return slot;
  }

  return null;
};

interface OpeningNowProps {
  organizationId: number;
  colorCode?: string;
  organization?: OrganizationDTO;
  showAddress?: boolean;
  showAllSlots?: boolean;
  sx?: SxProps<Theme>;
}

const OpenNow: FC<OpeningNowProps> = (props) => {
  const { organizationId, colorCode, organization, showAddress, sx, showAllSlots } = props;
  const isMountedRef = useIsMountedRef();
  const [businessHours, setBusinessHours] = useState<BusinessHoursDTO[] | null>(
    null
  );
  const [openSlot, setOpenSlot] = useState<BusinessHoursDTO | null>(null);
  const [nextOpenSlot, setNextOpenSlot] = useState<BusinessHoursDTO | null>(null);
  const [expanded, setExpanded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const getBusinessHours = useCallback(async (id: number) => {
    try {
      const response = await axios.get<BusinessHoursDTO[]>(
        `/api/businesshours/${id}`
      );
      if (isMountedRef.current) {
        if (response.data) {
          setBusinessHours(response.data);
          setOpenSlot(findOpenSlot(response.data));
          setNextOpenSlot(findNextOpenSlot(response.data));
        }
        setIsLoaded(true);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    getBusinessHours(organizationId);
  }, [getBusinessHours]);

  if (!isLoaded) {
    return (
      <Skeleton
        height={30}
        width="100%"
      />
    );
  }

  const getOpenDay = (nextOpenDay:BusinessHoursDTO) => {
    const now = new Date();

    if (nextOpenDay.day === now.getDay() && toDate(nextOpenDay.openTime).getTime() > now.getTime()) {
      return 'heute';
    }
    return `am ${DateHelper.getWeekDayNameShort(nextOpenDay.day)}`;
  };

  return (
    <Box sx={sx}>
      {showAddress && organization && (
      <Typography
        sx={{ fontWeight: 'bold' }}
        variant="body2"
        gutterBottom
        component="div"
      >
        {organization.streetName}
        {' '}
        {organization.streetNumber}
        ,
        {' '}
        {organization.city}
        ,
        {' '}
        {' '}
        {organization.zipCode}
      </Typography>
      )}
      {(openSlot || nextOpenSlot) && (
        <Stack
          direction="row"
          alignItems="center"
          display="flex"
        >
          {openSlot && (
          <Typography
            color={colorCode}
            sx={{ fontWeight: 'bold' }}
            variant="body2"
            gutterBottom
            component="div"
          >
            Geöffnet bis
            {' '}
            {formatTime(openSlot.closeTime)}
            {' '}
            Uhr
          </Typography>
          )}
          {!openSlot && (
          <>
            {nextOpenSlot && (
              <Typography
                sx={{ fontWeight: 'bold' }}
                variant="body2"
                gutterBottom
                component="div"
              >
                Öffnet
                {' '}
                {getOpenDay(nextOpenSlot)}
                {' '}
                um
                {' '}
                {formatTime(nextOpenSlot.openTime)}
                {' '}
                Uhr
              </Typography>
            )}
            {!nextOpenSlot && (
              <Typography
                sx={{ fontWeight: 'bold' }}
                variant="body2"
                gutterBottom
                component="div"
              >
                Derzeit geschlossen
              </Typography>
            )}
          </>
          )}
          {showAllSlots && (
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          )}
        </Stack>
      )}
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <OpeningHours businessHours={businessHours} />
      </Collapse>
    </Box>
  );
};

OpenNow.defaultProps = {
  showAddress: true,
  showAllSlots: true,
  colorCode: '#000000',
};

export default OpenNow;
