import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import makeStringUrlFriendly from 'src/utils/makeStringUrlFriendly';
import useAuth from '../hooks/useAuth';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated && user.organizationNr) {
    const url = `/${makeStringUrlFriendly(user.companyName)}-${user.organizationNr}`;
    return <Navigate to={url} />;
  } if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
