/**
 * Function that substitutes all umlauts
 * @param text Text, where the umlauts will be replaced
 * @returns String without umlauts
 */
const replaceUmlaute = (text:String) => {
  text = text.replace(/\u00dc/g, 'Ue');
  text = text.replace(/\u00fc/g, 'ue');
  text = text.replace(/\u00c4/g, 'Ae');
  text = text.replace(/\u00e4/g, 'ae');
  text = text.replace(/\u00d6/g, 'Oe');
  text = text.replace(/\u00f6/g, 'oe');
  text = text.replace(/\u00df/g, 'ss');
  return text;
};

/**
 * Function that makes a string url valid. All forbidden characters will be replaced with "-"
 * @param text
 * @returns Url friendly string
 */
const makeStringUrlFriendly = (text:String) => {
  if (text === undefined || text === null) {
    return 'org';
  }
  const result = replaceUmlaute(text);
  return result === undefined ? '' : result.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase();
};

export default makeStringUrlFriendly;
