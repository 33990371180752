import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import makeStringUrlFriendly from 'src/utils/makeStringUrlFriendly';
import useAuth from 'src/hooks/useAuth';
import SettingsIcon from '@mui/icons-material/Settings';
import { UserRole } from 'src/api/dto/user/userRole';
import { EnAuthGuardBehaviour } from 'src/types/enAuthGuardBehaviour';
import { AppConfig, serverConfig } from 'src/config';
import { KumaoTheme } from 'src/theme';
import StorefrontIcon from '@mui/icons-material/Storefront';
import EventIcon from '@mui/icons-material/Event';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Logo from './Logo';
import AuthGuard from './AuthGuard';
import UserMessageSnackbar from './widgets/userMessage/UserMessageSnackbar';

interface MainSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const MainSidebar: FC<MainSidebarProps> = (props) => {
  const { user, logout } = useAuth();
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const auth = useAuth();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      onMobileClose();
      document.location.href = '/'; // important to clean every cached variables
    } catch (err) {
      console.error(err);
      setOpenSnackBar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  return (
  // <Hidden lgUp>
    <div>
      <Drawer
        anchor="right"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            backgroundColor: 'background.default',
            width: 256
          }
        }}
      >
        { auth.isAuthenticated && (
          <Box>
            <Box sx={{ p: 2 }}>
              <Stack
                direction="row"
                spacing={2}
              >
                <Avatar
                  src={user.avatar ? serverConfig.baseIMGUrl + user.avatar : ''}
                  sx={{
                    height: 32,
                    width: 32,
                    marginTop: 0.5,
                    bgcolor: user.avatar ? '' : (theme: KumaoTheme) => theme.palette.primary.main
                  }}
                >
                  {user.avatar && (
                    <Typography
                      color="textPrimary"
                    >
                      {user.firstName && user.firstName.length > 0 ? user.firstName[0].toUpperCase() : ''}
                      {user.lastName && user.lastName.length > 0 ? user.lastName[0].toUpperCase() : ''}
                    </Typography>
                  )}
                </Avatar>
                <Box>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {user.name}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    {user.email}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Divider />
            <Box sx={{ mt: 2 }}>
              <AuthGuard
                minimumUserRole={UserRole.BusinessUser}
                unauthorizedBehaviour={EnAuthGuardBehaviour.Hide}
              >
                <MenuItem
                  component={RouterLink}
                  to={`/${makeStringUrlFriendly(user.companyName)}-${user.organizationNr}`}
                >
                  <ListItemIcon>
                    <StorefrontIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={(
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        Mein Unternehmensprofil
                      </Typography>
              )}
                  />
                </MenuItem>
                <MenuItem
                  component={RouterLink}
                  to="/mycalender"
                >
                  <ListItemIcon>
                    <EventIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={(
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        Terminübersicht
                      </Typography>
                    )}
                  />
                </MenuItem>
                {user && user.organizationNr === +AppConfig.KumaoOrgId && (
                <MenuItem
                  component={RouterLink}
                  to="/batcave"
                >
                  <ListItemIcon>
                    <AdminPanelSettingsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={(
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        Batcave
                      </Typography>
                    )}
                  />
                </MenuItem>
                )}
              </AuthGuard>
              <MenuItem
                component={RouterLink}
                to="/account"
              >
                <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={(
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      Einstellungen
                    </Typography>
                  )}
                />
              </MenuItem>
            </Box>
            <Box sx={{ p: 2 }}>
              <Button
                color="primary"
                fullWidth
                onClick={handleLogout}
                variant="outlined"
              >
                Ausloggen
              </Button>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <RouterLink to="/">
                <Logo />
              </RouterLink>
            </Box>
          </Box>
        )}
        <UserMessageSnackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          text="Oops beim Ausloggen ist leider etwas schiefgegangen. Bitte versuche es erneut."
          severity="error"
        />
      </Drawer>
    </div>
  // </Hidden>
  );
};

MainSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default MainSidebar;
