import {
  FC
} from 'react';
import {
  Button
} from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import useSession from 'src/hooks/useSession';

const SearchResetButton: FC = () => {
  const { session, saveSession } = useSession();

  const deleteSearchSession = () => {
    session.searchTerm = '';
    session.selectedSearchMode = 'Alle';
    saveSession({
      ...session
    });
  };

  if (session.searchTerm || session.selectedSearchMode !== 'Alle') {
    return (
      <Button
        color="secondary"
        variant="contained"
        sx={{ borderRadius: 5, marginRight: 0, minWidth: 0 }}
        onClick={deleteSearchSession}
      >
        <SearchOffIcon />
        Suche löschen
      </Button>
    );
  }
  return (null);
};
export default SearchResetButton;
