import type { FC, ReactNode } from 'react';
import {
  Box,
  Button,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';
import DialogBase from './DialogBase';

export interface OptButton {
  text: string;
  key: string;
  icon?: ReactNode;
}

export interface DialogMultipleOptionsProps {
  open: boolean;
  onClose: (option: string) => void;
  title: string;
  text?: string;
  buttons: OptButton[];
}

const DialogMultipleOptions: FC<DialogMultipleOptionsProps> = (props) => {
  const { onClose, open, title, text, buttons } = props;
  const hiddenMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose('');
  };

  return (
    <DialogBase
      onClose={handleClose}
      open={open}
    >
      <Typography
        color="#fff"
        variant="h5"
      >
        {title}
      </Typography>
      <Typography
        align="center"
        color="#fff"
        sx={{ mt: 2 }}
        variant="h6"
      >
        {text}
      </Typography>
      <Box
        pt={2}
        sx={{ width: '100%', float: 'right', mt: 1, mb: 1 }}
      >
        <Box sx={{ float: 'right', width: hiddenMdDown ? '100%' : 'auto' }}>
          {buttons.map((btn) => (
            <Button
              key={btn.key}
              size="large"
              variant="outlined"
              color="primary"
              startIcon={btn.icon}
              onClick={() => onClose(btn.key)}
              sx={{ width: hiddenMdDown ? '100%' : 'auto', ml: hiddenMdDown ? 0 : 2, borderRadius: 20, borderWidth: 2, backgroundColor: '#FFFFFF', mt: 1, mb: 1, '&:hover': { borderWidth: 2, backgroundColor: '#efefed' } }}
            >
              {btn.text}
            </Button>
          ))}
        </Box>
      </Box>
    </DialogBase>
  );
};

export default DialogMultipleOptions;
