import { FC } from 'react';
import {
  Container,
  Grid,
  Typography,
  Divider,
  Chip
} from '@mui/material';
import { OrganizationDTO } from 'src/api/dto/organization/organization';
import zeroPad from 'src/utils/zeroPad';
import { OfferedEventDTO } from 'src/api/dto/organization/offeredEvent';
import { EventType } from 'src/api/dto/organization/enEventType';
import ListAllSchedules from 'src/components/widgets/ListAllSchedules';

interface SummaryEventProps {
  organization: OrganizationDTO;
  selectedEvent: OfferedEventDTO;
  colorCode: string;
}

const SummaryEvent: FC<SummaryEventProps> = (props) => {
  const { organization, selectedEvent, colorCode } = props;

  return (
    <Container sx={{ p: 5, borderRadius: '10px', border: '1px solid #979797' }}>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          style={{ textAlign: 'center' }}
          sx={{ pb: 2 }}
          xs={12}
        >
          <Typography
            color={colorCode}
            sx={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: 15, pb: 1 }}
          >
            {organization.companyName}
          </Typography>
          <Typography sx={{ fontFamily: 'Roboto' }}>
            {organization.streetName}
            {' '}
            {organization.streetNumber}
            ,
          </Typography>
          <Typography sx={{ fontFamily: 'Roboto' }}>
            {organization.zipCode}
            {' '}
            {organization.city}
          </Typography>
        </Grid>
        <Grid
          item
          style={{ textAlign: 'center' }}
          xs={12}
        >
          <Divider>
            <Chip
              label={selectedEvent.description}
              sx={{ fontWeight: 'bold' }}
            />
          </Divider>
        </Grid>
        {selectedEvent.eventType === EventType.Event && (
          <>
            <Grid
              item
              xs={6}
              style={{ textAlign: 'right' }}
            >
              <Typography sx={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: 40 }}>
                {zeroPad(new Date(selectedEvent.schedules[0].start).getHours(), 2)}
                :
                {zeroPad(new Date(selectedEvent.schedules[0].start).getMinutes(), 2)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Typography sx={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: 19 }}>{new Date(selectedEvent.schedules[0].start).toLocaleDateString()}</Typography>
              <Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>
                Gesamtzeit:
                {' '}
                {selectedEvent.duration}
                {' '}
                min
              </Typography>
            </Grid>
          </>
        )}
        {selectedEvent.eventType === EventType.Course && (
        <>
          <Grid
            item
            xs={12}
            style={{ textAlign: 'center' }}
          >
            <ListAllSchedules schedules={selectedEvent.schedules} />
          </Grid>
        </>
        )}
        <Grid
          item
          style={{ textAlign: 'center' }}
          xs={12}
        >
          <Divider />
        </Grid>

        <Grid
          item
          style={{ textAlign: 'center' }}
          xs={12}
        >
          <Typography sx={{ fontFamily: 'Roboto', fontWeight: 700 }}>Gesamtsumme</Typography>
          <Typography sx={{ fontFamily: 'Roboto', fontWeight: 700 }}>
            {selectedEvent.price}
            {' '}
            €
          </Typography>
          <Typography sx={{ fontFamily: 'Roboto', pt: 3 }}>Bezahlung erfolgt vor Ort</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SummaryEvent;
