import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  LoginForm,
} from '../../components/authentication/login';

const Login: FC = () => (
  <>
    <Helmet>
      <title>Kumao | Anmelden</title>
    </Helmet>
    <LoginForm />
  </>
);

export default Login;
