import type { FC } from 'react';
import {
  Avatar,
  Stack,
  Typography,
  Box,
  Theme
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { serverConfig } from 'src/config';
import type { SxProps } from '@mui/system';

interface HeroProps {
  sx?: SxProps<Theme>;
  showAvatar?: boolean;
}

const Hero: FC<HeroProps> = (props) => {
  const { sx, showAvatar } = props;
  const auth = useAuth();

  return (
    <Stack
      direction="row"
      sx={{ ...sx }}
    >
      {showAvatar && (
      <Box sx={{ pt: 1.2 }}>
        <Avatar
          src={serverConfig.baseIMGUrl + auth.user.avatar}
          sx={{
            height: 32,
            width: 32,
          }}
        />
      </Box>
      )}
      <Box sx={{ pt: 2, pl: 0, pr: 1 }}>
        <Typography
          variant="h3"
          align="center"
        >
          Hallo
        </Typography>
      </Box>
      <Box sx={{ pt: 2 }}>
        <Typography
          variant="h3"
          align="center"
          color="primary"
        >
          {auth.user.name}
          !
        </Typography>
      </Box>
    </Stack>
  );
};

Hero.defaultProps = {
  showAvatar: false,
};

export default Hero;
