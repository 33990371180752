import { Branche } from 'src/types/branche';

export const branches: Branche[] = [
  {
    id: 8,
    name: 'Wellness & Beauty',
    picture: '/static/categories/scissors.png',
    color: '#aa4c45',
    top: 60,
    left: -30,
    rotate: 23,
    name2pl: 6
  },
  {
    id: 9,
    name: 'Körper & Sport',
    picture: '/static/categories/weights.png',
    color: '#87b4a3',
    top: 80,
    left: 20,
    rotate: 0,
    name2pl: 6
  },
  {
    id: 11,
    name: 'Kulinarik & Genuss',
    picture: '/static/categories/vegetable.png',
    color: '#95a95b',
    top: 70,
    left: 20,
    rotate: 0,
    name2pl: 6
  },
  {
    id: 10,
    name: 'Persönlichkeitsentwicklung',
    picture: '/static/categories/book.png',
    color: '#bb8637',
    top: 80,
    left: 15,
    rotate: 0,
    name2pl: 4,
    smallFont: true
  },
  {
    id: 12,
    name: 'Handwerk',
    picture: '/static/categories/plumber.png',
    color: '#515f7a',
    top: 70,
    left: 35,
    rotate: 82,
    name2pl: 6
  },
  {
    id: 13,
    name: 'Gesundheit',
    picture: '/static/categories/medical.png',
    color: '#cfbd53',
    top: 70,
    left: 35,
    rotate: 0,
    name2pl: 6
  },
  {
    id: 14,
    name: 'Technologie',
    picture: '/static/categories/chip.png',
    color: '#7f5f7c',
    top: 75,
    left: 10,
    rotate: 0,
    name2pl: 6
  },
  {
    id: 18,
    name: 'Kunst & Design',
    picture: '/static/categories/color.png',
    color: '#c57a7b',
    top: 80,
    left: 15,
    rotate: 0,
    name2pl: 6
  },
  {
    id: 15,
    name: 'Recht & Finanzen',
    picture: '/static/categories/scale.png',
    color: '#703739',
    top: 70,
    left: 55,
    rotate: 0,
    name2pl: 3
  },
  {
    id: 16,
    name: 'Freizeit & Vergüngen',
    picture: '/static/categories/freetime.png',
    color: '#5d755d',
    top: 80,
    left: 55,
    rotate: 0,
    name2pl: 2
  },
  {
    id: 17,
    name: 'Tiere',
    picture: '/static/categories/dog.png',
    color: '#907165',
    top: 65,
    left: 35,
    rotate: 0,
    name2pl: 6
  },
  {
    id: 19,
    name: 'Sonstige Dienstleister',
    picture: '/static/categories/flower.png',
    color: '#777a7b',
    top: 80,
    left: 60,
    rotate: 0,
    name2pl: 1
  }
];
