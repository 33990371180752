export enum UserRole {
  User = 'ROLE_USER',
  Admin = 'ROLE_ADMIN', // Kumao Admin
  BusinessUser = 'ROLE_BUSINESSUSER',
  BusinessManager = 'ROLE_BUSINESSMANAGER',
  BusinessAdmin = 'ROLE_BUSINESSADMIN',
  Anonymous = ''
}

export interface UserRoleDTO {
  role: UserRole;
  userName: string;
}
