/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { UILoggingDTO, LoggingLevel } from 'src/api/dto/common/uiLogging';
import axios from 'src/lib/axios';
import DateHelper from 'src/utils/dateHelper';
import isHttpStatusOk from 'src/utils/isHttpStatusOk';

const send2Log = async (
  level: LoggingLevel,
  msg: any,
  page: string,
  oldConsole: any
) => {
  try {
    let message: string = '';
    if (typeof msg === 'string') {
      message = msg;
    } else if ('message' in msg) {
      message = msg.message;
    } else {
      message = JSON.stringify(msg);
    }

    const data: UILoggingDTO = {
      enumLoggingLevel: level,
      isoDateTime: DateHelper.toISOLocalDateTime(new Date()),
      message,
      page,
      sessionObject: Error().stack
    };

    const response = await axios.post('/api/uilogging', data);
    return isHttpStatusOk(response.status);
  } catch (err) {
    oldConsole.error(err);
  }

  return false;
};

const overrideConsole = (oldConsole = window.console) => ({
  ...oldConsole,
  log(text) {
    // oldConsole.log(text);
  },
  info(text) {
    // oldConsole.info(text);
  },
  warn(text) {
    oldConsole.warn(text);
  },
  error(text) {
    oldConsole.error(text);

    send2Log('ERROR', text, window.location.pathname, oldConsole);
  }
});

const useConsoleOverride = () => {
  useEffect(() => {
    console.log('Console overridden');
    window.console = overrideConsole();
  }, []);
};

export default useConsoleOverride;
