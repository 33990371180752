import { FC, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormHelperText,
  Typography,
  Checkbox,
  Link,
  TextField
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import UserMessageSnackbar from 'src/components/widgets/userMessage/UserMessageSnackbar';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

interface LoginJWTProps {
  redirectTo?: string;
}

const LoginJWT: FC<LoginJWTProps> = (props) => {
  const { redirectTo } = props;
  const isMountedRef = useIsMountedRef();
  const { login } = useAuth() as any;
  const navigate = useNavigate();
  const [showErrorText, setShowErrorText] = useState(false);
  const [errorText, setErrorText] = useState('');

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowErrorText(false);
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        rememberme: true,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Muss eine gültige E-Mail sein').max(255).required('E-Mail ist erforderlich'),
        password: Yup.string().max(255).required('Passwort ist erforderlich')
      })}
      onSubmit={async (values, {
        setStatus,
        setSubmitting
      }) => {
        setShowErrorText(false);
        try {
          await login(values.email, values.password, values.rememberme);
          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
            navigate(redirectTo);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setShowErrorText(true);
            setErrorText(err.message);
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form
          noValidate
          onSubmit={handleSubmit}
        >
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            autoFocus
            helperText={touched.email && errors.email}
            label="E-Mail"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Passwort"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Link
            component={RouterLink}
            to="/lost-password"
            variant="body2"
            color="textPrimary"
            state={{ email: values.email }}
          >
            Passwort vergessen?
          </Link>
          <Box
            sx={{ display: 'none' }}
            alignItems="center"
            display="flex"
            mt={2}
            ml={-1}
          >
            <Checkbox
              checked={values.rememberme}
              name="rememberme"
              onChange={handleChange}
              color="primary"
            />
            <Typography
              variant="body2"
              color="textPrimary"
            >
              Angemeldet bleiben
            </Typography>
          </Box>
          {Boolean(touched.rememberme && errors.rememberme) && (
            <FormHelperText error>
              {errors.rememberme}
            </FormHelperText>
          )}
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Einloggen
            </Button>
          </Box>
          <UserMessageSnackbar
            open={showErrorText}
            title={errorText}
            severity="error"
            onClose={handleCloseSnackbar}
          />
        </form>
      )}
    </Formik>
  );
};

LoginJWT.defaultProps = {
  redirectTo: '/'
};

export default LoginJWT;
