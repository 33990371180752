import { useState, useEffect } from 'react';
import type { FC } from 'react';
import {
  Container,
  Grid,
  Button,
  useMediaQuery,
  Theme
} from '@mui/material';
import { OrganizationDTO } from 'src/api/dto/organization/organization';
import useSettings from 'src/hooks/useSettings';
import { SelectedService } from 'src/types/selectedService';
import { OfferedEventDTO } from 'src/api/dto/organization/offeredEvent';
import UserData from './confirmation/UserData';
import Summary from './confirmation/Summary';
import { UserDataFields } from './confirmation/UserDataFields';
import SummaryEvent from './confirmation/SummaryEvent';
import { UserDataCustomizedFields } from './customizedFields/UserDataCustomizedFields';
import UserDataCustomized from './customizedFields/UserDataCustomized';

interface BookingConfirmationProps {
  colorCode: string;
  organization: OrganizationDTO;
  selectedServices: SelectedService[];
  selectedEvent: OfferedEventDTO;
  onShowSelection: () => void;
  onBookSlot: (userDataValues: UserDataFields, userDataValuesCustomized?: UserDataCustomizedFields[]) => void;
}

const BookingConfirmation: FC<BookingConfirmationProps> = (props) => {
  const { colorCode, organization, selectedServices, selectedEvent, onShowSelection, onBookSlot } = props;
  const { settings } = useSettings();
  const hiddenMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [submitUserData, setSubmitUserData] = useState<boolean>(false);
  const [disableBtn, setDisableBtn] = useState<boolean>(false);
  const [showBtn, setShowBtn] = useState<boolean>(true);
  const [userData, setUserData] = useState<UserDataFields | null>(null);
  const [userDataCustomized, setUserDataCustomized] = useState<UserDataCustomizedFields[] | null>(null);
  const [openSubmitIds, setOpenSubmitIds] = useState<number[]>();

  const servicesCustomizedFields = selectedServices.filter((x) => x?.customizedBookingFields?.find((item) => item.serviceId));

  const onSubmitUserData = (userDataValues: UserDataFields) : boolean => {
    // setDisableBtn(false);
    // onBookSlot(userDataValues);
    setUserData(userDataValues);
    return true;
  };

  const onSubmitUserDataCustomized = (userDataValues: UserDataCustomizedFields[], selectedServiceId: number) : boolean => {
    // setDisableBtn(false);
    const newOpenIds = openSubmitIds.filter((e) => e !== selectedServiceId); // wait for all forms to be submitted and remove id from openSubmitId if submit was successful
    let data = userDataCustomized ?? [];
    data = data.concat(userDataValues);

    setUserDataCustomized(data);
    setOpenSubmitIds(newOpenIds);
    return true;
  };

  const onChangeShowLogin = (showLogin: boolean) : void => {
    setShowBtn(!showLogin);
  };

  const clickBookSlot = () => {
    if (servicesCustomizedFields && servicesCustomizedFields.length > 0) {
      setOpenSubmitIds(servicesCustomizedFields.map((x) => x.id));
      setUserDataCustomized(null);
    }
    setSubmitUserData(true);
  };

  useEffect(() => {
    if (submitUserData) {
      setSubmitUserData(false);
    }
  }, [submitUserData]);

  useEffect(() => {
    if (userData && (!servicesCustomizedFields || servicesCustomizedFields.length === 0)) {
      setDisableBtn(false);
      onBookSlot(userData);
    } else if (userData && userDataCustomized && openSubmitIds?.length === 0) { // wait until openSubmitIds is empty
      setDisableBtn(false);
      onBookSlot(userData, userDataCustomized);
    }
  }, [userData, userDataCustomized, openSubmitIds]);

  if (!organization) {
    return null;
  }

  return (
    <>
      <Container
        maxWidth={settings.compact ? 'lg' : false}
        sx={{ pt: 4 }}
      >
        <Grid
          container
          spacing={5}
        >
          <Grid
            item
            lg={8}
            md={7}
            xs={12}
          >
            <UserData
              submitUserData={onSubmitUserData}
              submitForm={submitUserData}
              onChangeShowLogin={onChangeShowLogin}
              showRemarks={organization.showCommentBookingDialog}
              showPhone={organization.showPhoneNumberBookingDialog}
              phoneMandatory={organization.phoneNumberBookingDialogMandatory}
            />
            {servicesCustomizedFields?.map((service) => (
              <UserDataCustomized
                key={service.id}
                id={service.id}
                submitUserData={onSubmitUserDataCustomized}
                submitForm={submitUserData}
                serviceId={service.service.serviceID}
                serviceDescription={service.service.description}
                customizedBookingFieldsDTO={service.customizedBookingFields}
                sx={{ mt: 2 }}
              />
            ))}
            <Button
              disabled={disableBtn}
              fullWidth
              color="primary"
              size="large"
              sx={{ borderRadius: '20px', borderWidth: '2px', fontFamily: 'Roboto', mt: 2, display: (!hiddenMdDown && showBtn) ? 'block' : 'none' }}
              variant="contained"
              onClick={clickBookSlot}
            >
              {selectedServices && selectedServices.length > 0 && (
                'Termin reservieren'
              )}
              {selectedEvent && ('Anmelden')}
            </Button>
          </Grid>
          <Grid
            item
            lg={4}
            md={5}
            xs={12}
          >
            {selectedServices && selectedServices.length > 0 && (
            <Summary
              organization={organization}
              selectedServices={selectedServices}
              colorCode={colorCode}
              onShowSelection={onShowSelection}
            />
            )}
            {selectedEvent && (
            <SummaryEvent
              organization={organization}
              selectedEvent={selectedEvent}
              colorCode={colorCode}
            />
            )}
            <Button
              disabled={disableBtn}
              fullWidth
              color="primary"
              size="large"
              sx={{ borderRadius: '20px', borderWidth: '2px', fontFamily: 'Roboto', mt: 2, display: (hiddenMdDown && showBtn) ? 'block' : 'none' }}
              variant="contained"
              onClick={clickBookSlot}
            >
              {selectedServices && selectedServices.length > 0 && (
                'Termin reservieren'
              )}
              {selectedEvent && ('Anmelden')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default BookingConfirmation;
