import { FC } from 'react';
import {
  Snackbar,
  Alert,
  AlertColor,
  AlertTitle,
} from '@mui/material';

export interface UserMessageSnackbarProps {
  open: boolean;
  text?: string;
  severity?: AlertColor;
  title?: string;
  onClose?: (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => void;
}

const UserMessageSnackbar: FC<UserMessageSnackbarProps> = (props) => {
  const { open, title, text, severity, onClose } = props;

  return (
    <Snackbar
      open={open}
      autoHideDuration={10000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
      >
        <AlertTitle>{title}</AlertTitle>
        {text ?? 'Netzwerkfehler: Bitte überprüfe deine Internetverbindung'}
      </Alert>
    </Snackbar>
  );
};

UserMessageSnackbar.defaultProps = {
  severity: 'error',
  title: 'Upps, es scheint etwas schiefgegangen zu sein.',
  text: ''
};

export default UserMessageSnackbar;
