import React, { useState, useEffect, useRef, useCallback } from 'react';
import type { FC } from 'react';
import {
  Container,
  Grid,
  TextField,
  Theme,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Link,
  Chip,
  Divider,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import { useBeforeUnload } from 'react-router-dom';
import { Formik } from 'formik';
import { SxProps } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { CustomizedBookingFieldType } from 'src/types/customizedBookingFields/customizedBookingFieldsType';
import { CustomizedBookingFields } from 'src/types/customizedBookingFields/customizedBookingFields';
import useSession from 'src/hooks/useSession';
import { CustomizedBookingFieldsDTO } from 'src/api/dto/booking/customizedBookingFields';
import { UserDataCustomizedFields } from './UserDataCustomizedFields';

interface UserDataCustomizedProps {
  id: number;
  submitForm: boolean;
  submitUserData: (userDataValues: UserDataCustomizedFields[], selectedServiceId: number) => boolean;
  serviceId: number;
  serviceDescription?: string;
  customizedBookingFieldsDTO: CustomizedBookingFieldsDTO[];
  saveInSession?: boolean;
  sx?: SxProps<Theme>;
}

const UserDataCustomized: FC<UserDataCustomizedProps> = (props) => {
  const { submitForm, submitUserData, sx, id, serviceId, serviceDescription, customizedBookingFieldsDTO, saveInSession } = props;
  const { session, saveSession } = useSession();
  const [customizedBookingFields, setCustomizedBookingFields] = useState<CustomizedBookingFields[] | null>(null);
  const [formData, setFormData] = useState(null);
  const [fieldValidationSchema, setFieldValidationSchema] = useState(null);

  const myForm = useRef(null);

  const CustomFieldBox = styled(TextField)(() => ({
    '& fieldset': {
      borderRadius: '25px',
    },
  }));

  const CustomDatePicker = styled(DatePicker)(() => ({
    '& fieldset': {
      borderRadius: '25px',
    },
  }));

  const fkey = (fieldId: number) => `f_${id}_${fieldId}`;

  const baseValueFromFieldType = (type: CustomizedBookingFieldType) => {
    switch (type) {
      case CustomizedBookingFieldType.DATE:
        return null;

      case CustomizedBookingFieldType.NUMBER:
        return 0;

      case CustomizedBookingFieldType.BOOLEAN:
        return false;

      default:
        return '';
    }
  };

  const getUserDataCustomizedFields = (values: any) => customizedBookingFields.map((field) => {
    let fieldValue = '';

    switch (field.type) {
      case CustomizedBookingFieldType.DATE:
        fieldValue = new Date(values[fkey(field.id)]).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
        break;

      default:
        fieldValue = values[fkey(field.id)];
        break;
    }

    const newItem: UserDataCustomizedFields = {
      serviceID: field.serviceId,
      eventID: field.eventId,
      customizedBookingFieldsId: field.id,
      displayText: field.displayText,
      fieldGroup: field.fieldGroup,
      value: fieldValue,
    };

    return newItem;
  });

  useEffect(() => {
    if (submitForm) {
      myForm.current.submitForm();
    }
  }, [submitForm]);

  useEffect(() => {
    if (customizedBookingFieldsDTO) {
      const bookingFields = customizedBookingFieldsDTO.map((field) => {
        const newItem: CustomizedBookingFields = {
          ...field,
          multiline: field.type === CustomizedBookingFieldType.MULTILINE,
        };

        return newItem;
      });

      // create initial data structure for form
      let formContent;

      if (saveInSession && session?.userDataCustomizedFields) {
        const savedFormData = session.userDataCustomizedFields.filter((x) => x.serviceID === serviceId);
        if (savedFormData?.length > 0) {
          formContent = savedFormData.map((field) => {
            const item = {};

            switch (field.value) {
              case 'Invalid Date':
                item[fkey(field.customizedBookingFieldsId)] = null;
                break;

              default:
                item[fkey(field.customizedBookingFieldsId)] = field.value;
            }
            return item;
          }).reduce((prev, curr) => Object.assign(prev, curr));
        }
      }

      if (formContent === undefined) {
        formContent = bookingFields.map((field) => {
          const item = {};
          item[fkey(field.id)] = baseValueFromFieldType(field.type);

          return item;
        }).reduce((prev, curr) => Object.assign(prev, curr));
      }

      // create validation schema for form
      const validationItems = bookingFields.filter((x) => x.mandatory).map((field) => {
        const item = {};

        if (field.mandatory) {
          switch (field.type) {
            case CustomizedBookingFieldType.DATE:
              item[fkey(field.id)] = Yup.date().nullable().required('*erforderlich');
              break;

            case CustomizedBookingFieldType.NUMBER:
              item[fkey(field.id)] = Yup.number().positive('Wert muss positiv sein').required('*erforderlich');
              break;

            case CustomizedBookingFieldType.BOOLEAN:
              item[fkey(field.id)] = Yup.bool().oneOf([true], '*erforderlich');
              break;

            default: // Text, Multiline
              item[fkey(field.id)] = Yup.string().max(field.multiline ? 255 : 100).required('*erforderlich');
              break;
          }
        }

        return item;
      });

      let validationSchema = {};
      if (validationItems?.length > 0) {
        validationSchema = validationItems.reduce((prev, curr) => Object.assign(prev, curr));
      }

      setFormData(formContent);
      setCustomizedBookingFields(bookingFields);
      setFieldValidationSchema(validationSchema);
    } else {
      setCustomizedBookingFields(null);
    }
  }, [customizedBookingFieldsDTO]);

  useBeforeUnload(
    useCallback(() => {
      if (saveInSession) {
        saveSession({
          ...session
        });
      }
    }, [])
  );

  const customHandleBlur = () => {
    session.userDataCustomizedFields = getUserDataCustomizedFields(myForm.current.values);
  };

  if (!customizedBookingFields || customizedBookingFields.length === 0) { return null; }

  return (
    <>
      <Container sx={{ borderRadius: '20px', boxShadow: '0px -5px 18px rgba(0, 0, 0, 0.1)', pt: 1, ...sx }}>
        <Formik
          enableReinitialize
          innerRef={myForm}
          initialValues={{
            submit: null,
            ...formData,
          }}
          validationSchema={Yup.object().shape(fieldValidationSchema)}
          onSubmit={async (values, {
            setStatus,
            setSubmitting
          }) => {
            try {
              const userDataValues = getUserDataCustomizedFields(values);

              console.log(`Submit values #${id}`, userDataValues);

              if (submitUserData(userDataValues, id)) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
          }): JSX.Element => (
            <form
              onSubmit={handleSubmit}
            >
              <Grid
                container
                spacing={2}
                sx={{ p: 2, pb: 4 }}
              >
                {serviceDescription && (
                <Grid
                  item
                  xs={12}
                  sx={{ pb: 1 }}
                >
                  <Divider>
                    <Chip
                      label={serviceDescription}
                      sx={{ fontWeight: 'bold' }}
                    />
                  </Divider>
                </Grid>
                )}
                {customizedBookingFields.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)).map((item, idx, arr) => (
                  <React.Fragment key={item.id}>
                    {(idx === 0 || item.fieldGroup !== arr[idx - 1].fieldGroup) && (
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography sx={{ color: '#1B1D21', fontSize: 16, fontFamily: 'Rubik', fontWeight: 600 }}>
                        {item.fieldGroup}
                      </Typography>
                    </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      md={item.multiline ? false : 6}
                    >
                      {(item.type === CustomizedBookingFieldType.TEXT || item.type === CustomizedBookingFieldType.MULTILINE || item.type === CustomizedBookingFieldType.NUMBER) && (
                      <CustomFieldBox
                        label={item.displayText}
                        variant="outlined"
                        fullWidth
                        required={Boolean(item.mandatory)}
                        helperText={touched[fkey(item.id)] && errors[fkey(item.id)]}
                        inputProps={{ maxLength: item.multiline ? 255 : 100 }}
                        name={fkey(item.id)}
                        onBlur={(e) => {
                          handleBlur(e);
                          customHandleBlur();
                        }}
                        onChange={handleChange}
                        error={Boolean(touched[fkey(item.id)] && errors[fkey(item.id)])}
                        value={values[fkey(item.id)]}
                        type={item.type.toString()}
                        multiline={item.multiline}
                        minRows={item.multiline ? 2 : 1}
                      />
                      )}
                      {item.type === CustomizedBookingFieldType.DATE && (
                      <CustomDatePicker
                        renderInput={(props1) => (
                          <TextField
                            {...props1}
                            error={Boolean(touched[fkey(item.id)] && errors[fkey(item.id)])}
                            helperText={touched[fkey(item.id)] && errors[fkey(item.id)]}
                            label={item.displayText}
                            name={fkey(item.id)}
                            onBlur={handleBlur}
                            margin="normal"
                            variant="outlined"
                            required={Boolean(item.mandatory)}
                          />
                        )}
                        mask="__.__.____"
                        label={item.displayText}
                        value={values[fkey(item.id)]}
                        inputFormat="dd.MM.yyyy"
                        onChange={(newValue) => {
                          setFieldValue(fkey(item.id), newValue);
                          customHandleBlur();
                        }}
                      />
                      )}
                      {item.type === CustomizedBookingFieldType.BOOLEAN && (
                      <FormControl>
                        <FormControlLabel
                          label={item.displayText}
                          control={(
                            <Checkbox
                              sx={{
                                ...(Boolean(errors[fkey(item.id)]) && { color: '#f44336' }),
                              }}
                              required={Boolean(item.mandatory)}
                              checked={Boolean(values[fkey(item.id)])}
                              name={fkey(item.id)}
                              onChange={handleChange}
                              color="primary"
                              onBlur={(e) => {
                                handleBlur(e);
                                customHandleBlur();
                              }}
                            />
                                          )}
                        />
                        {Boolean(errors[fkey(item.id)]) && (
                        <FormHelperText
                          error
                        >
                          {errors[fkey(item.id)]}
                        </FormHelperText>
                        )}
                        {item.hyperLink && (
                        <Link
                          href={item.hyperLink}
                          target="_blank"
                          rel="noopener"
                        >
                          Weitere Informationen findest du hier
                        </Link>
                        )}
                      </FormControl>
                      )}
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </form>
          )}
        </Formik>
      </Container>
    </>
  );
};

UserDataCustomized.defaultProps = {
  serviceDescription: '',
  saveInSession: true,
};

export default UserDataCustomized;
