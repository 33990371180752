import type { FC } from 'react';
import { Theme, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { BusinessHoursDTO } from 'src/api/dto/organization/businessHours';
import DateHelper from '../../../utils/dateHelper';

// import PropTypes from 'prop-types';

interface OpeningHoursProps {
  businessHours: BusinessHoursDTO[];
  sx?: SxProps<Theme>;
}

const Item = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 'bold',
  paddingTop: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const formatTime = (dateStr: string) => new Date(`1970-01-01T${dateStr}`).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });

const OpeningHours: FC<OpeningHoursProps> = (props) => {
  const { businessHours } = props;

  return (
    <Grid
      sx={{ paddingTop: 1 }}
      container
    >
      <Grid
        item
      >
        {businessHours.filter((x) => x.openTime !== x.closeTime).sort((a, b) => a.day - b.day).map((item, idx) => (
          <Grid
            container
            key={item.id}
          >
            <Grid
              item
              sx={{ width: 50, padding: 0 }}
            >
              {(idx === 0 || item.day !== businessHours[idx - 1].day) && (
              <Item>{DateHelper.getWeekDayNameShort(item.day)}</Item>
              )}
            </Grid>
            {(item.closed === true) && (
              <Grid
                item
              >
                <Item sx={{ color: (theme) => theme.palette.error.main }}>Geschlossen</Item>
                {(item.closed === true) && (<Item>{item.closedDescription}</Item>)}
              </Grid>
            )}
            <Grid
              item
            >
              {item.openTime !== item.closeTime && item.closed === false && (
                <Item>
                  {formatTime(item.openTime)}
                  {' '}
                  bis
                  {' '}
                  {formatTime(item.closeTime)}
                  {' '}
                  Uhr
                </Item>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default OpeningHours;
