import { makeStyles, createStyles } from '@mui/styles';
import type { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme) => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    '*::-webkit-scrollbar': {
      width: '1em',
    },
    '*::-webkit-scrollbar-track': {
      background: '#e8efd6',
      borderRadius: '10px'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#80a51b',
      borderRadius: '10px',
      borderColor: 'white',
      borderStyle: 'solid'
    }
  }
}));

const GlobalStyles: FC = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
