import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material';
import type { FC } from 'react';
import useSettings from 'src/hooks/useSettings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Hero } from '../home';
import Bookings from './dashboard/Bookings';

const BookingDashboard: FC = () => {
  const { settings } = useSettings();

  return (
    <>
      <Container
        maxWidth={settings.compact ? 'xl' : false}
        disableGutters
        sx={{ px: 1 }}
      >
        <Hero />
        <Accordion
          sx={{ mt: 2 }}
          defaultExpanded
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography
              variant="h3"
            >
              Deine Termine
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Bookings />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              variant="h3"
            >
              Meine vergangenen Termine
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Bookings
              showPastBookings
            />
          </AccordionDetails>
        </Accordion>
      </Container>
    </>
  );
};

export default BookingDashboard;
