import { useState, useEffect } from 'react';
import type { FC } from 'react';
import {
  Box,
  Link,
  Container,
  Grid,
  Typography,
  Theme,
  useMediaQuery,
  Button
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { OrganizationDTO } from 'src/api/dto/organization/organization';
import useSettings from 'src/hooks/useSettings';
import useSession from 'src/hooks/useSession';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ServiceBooking from 'src/components/booking/selection/ServiceBooking';
import makeStringUrlFriendly from 'src/utils/makeStringUrlFriendly';
import addPluralLetter from 'src/utils/addPluralLetter';
import { SelectedService } from 'src/types/selectedService';
import BookingSlotPicker from './selection/BookingSlotPicker';

interface BookingSelectionProps {
  organizationNr: number;
  colorCode: string;
  organization: OrganizationDTO;
  onConfirm: (selectedServices: SelectedService[]) => void;
}

const BookingSelection: FC<BookingSelectionProps> = (props) => {
  const { organizationNr, colorCode, organization, onConfirm } = props;
  const { settings } = useSettings();
  const { session, saveSession, updateServices, updateSelectedServices } = useSession();
  const [services, setServices] = useState<SelectedService[]>([]);
  const [selectedServiceID, setSelectedServiceID] = useState<Number>(0);
  const hiddenMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const showNextButton = () => {
    let hasEveryServiceSlot = true;
    if (services.length > 0) {
      services.forEach((item) => hasEveryServiceSlot = hasEveryServiceSlot && (item.selectedSlot !== null));
      return hasEveryServiceSlot;
    }
    return false;
  };

  const getSelectedService = (): SelectedService => {
    const result = services.find((item) => item.id === selectedServiceID);
    if (result) { return result; }
    return null;
  };

  const onConfirmSlots = () => {
    onConfirm(services);
  };

  const onSelectedSlotChanged = (slot: Date) => {
    const preAllServicesBooked = session.selectedServices.filter((item) => item.organizationNr === organizationNr).find((item) => item.selectedSlot === null) === undefined;
    session.selectedServices.find((item) => item.id === selectedServiceID).selectedSlot = slot;
    saveSession({
      ...session
    });
    const postAllServicesBooked = session.selectedServices.filter((item) => item.organizationNr === organizationNr).find((item) => item.selectedSlot === null) === undefined;
    // If the slot is the last selected one go to the booking page
    if (!preAllServicesBooked && postAllServicesBooked) {
      onConfirmSlots();
    }
    // Select next service if slot has been choosen
    if (!postAllServicesBooked && !hiddenMdDown) {
      setSelectedServiceID(session.selectedServices.filter((item) => item.organizationNr === organizationNr).find((item) => item.selectedSlot === null).id);
    }
    // On mobile close selection
    if (hiddenMdDown) {
      window.scrollTo(0, 0);
      setSelectedServiceID(0);
    }
  };

  const onSelectService = (id: Number) => {
    if (!hiddenMdDown || id !== selectedServiceID) {
      setSelectedServiceID(id);
    } else if (id === selectedServiceID) { setSelectedServiceID(0); }
  };
  const getSelectedServiceDuration = () => services.map((a) => a.service.duration).reduce((accumulator, current) => accumulator + current, 0);
  const getSelectedServiceAmount = () => services.map((a) => a.service.price).reduce((accumulator, current) => accumulator + current, 0);

  useEffect(() => {
    if (session.selectedServices.filter((item) => item.organizationNr === organizationNr).length !== services.length) {
      setServices(session.selectedServices.filter((item) => item.organizationNr === organizationNr));
    }
  }, [session]);

  useEffect(() => {
    if (!hiddenMdDown && services && services.length > 0 && !services.find((item) => item.id === selectedServiceID)) {
      let { id } = services[0];
      const freeItem = services.find((item) => item.selectedSlot === null);
      if (freeItem) id = freeItem.id;
      setSelectedServiceID(id);
    }
    updateServices(organizationNr);
    updateSelectedServices(organizationNr);
  }, [services]);

  const getNextButtonJSX = () => {
    if (!showNextButton()) { return null; } // currently disabled

    return (
      <Grid
        item
        md={12}
        sx={{ mt: 8, backgroundColor: '#F5F6FA', mr: hiddenMdDown ? 0 : 4 }}
      >
        <Grid
          container
          spacing={1}
          sx={{ p: 4 }}
        >
          <Grid
            item
            xs={6}
          >
            <Typography sx={{ fontWeight: 700, fontSize: '21px' }}>
              {services.length}
              {' '}
              Service
              {addPluralLetter('s', services.length)}
              {' '}
              ausgewählt
            </Typography>
            <Typography sx={{ color: '#4F5A68', fontFamily: 'Roboto', fontWeight: 700, fontSize: '16px', pt: 1 }}>
              Gesamtdauer
              {' '}
              {getSelectedServiceDuration()}
              {' '}
              min
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Typography
              textAlign="right"
              sx={{ color: '#4F5A68', fontFamily: 'Roboto', fontWeight: 700, fontSize: '23px' }}
            >
              Gesamt
              <wbr />
              summe
            </Typography>
            <Typography
              textAlign="right"
              sx={{ color: '#4F5A68', fontFamily: 'Roboto', fontWeight: 700, fontSize: '23px' }}
            >
              {getSelectedServiceAmount()}
              €
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Typography
              color={colorCode}
              sx={{ fontFamily: 'Roboto', fontWeight: 700, fontSize: 15, pt: 4 }}
            >
              {organization.companyName}
            </Typography>
            <Typography sx={{ fontFamily: 'Roboto' }}>
              {organization.streetName}
              {' '}
              {organization.streetNumber}
              {', '}
              {organization.zipCode}
              {' '}
              {organization.city}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Box sx={{ float: 'right', pt: 4 }}>
              <Button
                color="primary"
                size="large"
                sx={{ borderRadius: '20px', borderWidth: '2px', fontFamily: 'Roboto' }}
                variant="contained"
                onClick={onConfirmSlots}
              >
                Termin reservieren
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  if (!organization) {
    return null;
  }

  return (
    <>
      <Container
        maxWidth={settings.compact ? 'lg' : false}
        disableGutters
        sx={{ pt: 0 }}
      >
        <Grid
          container
          sx={{ pt: 1 }}
        >
          <Grid
            item
            md={8}
            xs={12}
            sx={{ mt: 4 }}
          >
            <ServiceBooking
              organizationNr={organizationNr}
              onSelectedSlot={onSelectedSlotChanged}
              colorCode={colorCode}
              selectedId={selectedServiceID}
              onSelect={onSelectService}
              sessionServices={services}
            />
            <Link
              component={RouterLink}
              to={`/${makeStringUrlFriendly(organization.companyName)}-${organization.organizationNr}?tab=services`}
              underline="none"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 6, mb: 4 }}
              >

                <AddCircleOutlineIcon
                  color="primary"
                  fontSize="large"
                  sx={{ mr: 1 }}
                />
                <Typography
                  sx={{ color: '#979797', fontSize: hiddenMdDown ? 16 : 20, fontWeight: 700, fontFamily: 'Rubik' }}
                >
                  Weiteren Service hinzufügen
                </Typography>
              </Box>
            </Link>
            {getNextButtonJSX()}
          </Grid>
          {!hiddenMdDown && (
          <Grid
            item
            md={4}
            xs={12}
            sx={{ width: '100%' }}
          >
            {services.length > 0 && (
            <BookingSlotPicker
              service={getSelectedService()}
              organizationNr={organizationNr}
              onSelectedSlot={onSelectedSlotChanged}
              selectedDate={getSelectedService()?.selectedSlot}
              sessionServices={services}
            />
            )}
          </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default BookingSelection;
