import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import LoginJWT from './LoginJWT';

interface LoginFormProps {
  embedded?: boolean;
  redirectTo?: string;
}

const LoginForm: FC<LoginFormProps> = (props) => {
  const { embedded, redirectTo } = props;
  const { platform } = useAuth() as any;

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: embedded ? '0vh' : '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: embedded ? '0px' : '80px' }}
        >
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 0
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    Bei Kumao anmelden
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                {platform === 'JWT' && (
                <LoginJWT
                  redirectTo={redirectTo}
                />
                )}
              </Box>
              {!embedded && (
              <>
                <Divider sx={{ my: 3 }} />
                <Link
                  component={RouterLink}
                  to="/register"
                  variant="body2"
                  color="textSecondary"
                >
                  Noch nicht registriert? - Jetzt mitmachen!
                </Link>
              </>
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

LoginForm.defaultProps = {
  embedded: false,
  redirectTo: '/'
};

export default LoginForm;
