import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Theme,
  Toolbar,
  useMediaQuery
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useSession from 'src/hooks/useSession';
import MenuIcon from '../icons/Menu';
import Logo from './Logo';
import NotificationsPopover from './NotificationsPopover';
import Loading from './widgets/overlays/Loading';

interface MainNavbarProps {
  onSidebarMobileOpen?: () => void;
}

const MainNavbar: FC<MainNavbarProps> = (props) => {
  const { onSidebarMobileOpen } = props;
  const auth = useAuth();
  const navigate = useNavigate();
  const { session } = useSession();
  const hiddenSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  // const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  // const hiddenDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  // const hiddenMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 5px;;',
        opacity: 0.97
      }}
    >
      {session?.isLoading && (<Loading />)}
      <Toolbar
        sx={{ minHeight: 64 }}
        disableGutters={hiddenSmDown && !auth.isAuthenticated}
      >
        <>
          <RouterLink to="/">
            <Logo
              responsive
            />
          </RouterLink>
          { /* </Hidden> */ }
          <Box sx={{ flexGrow: 1 }} />
          {!auth.isAuthenticated && (
          <div>
            { /* <Hidden mdDown> */ }
            { /* hiddenMdDown || */ (
              <div>
                <Box sx={{ float: 'left' }}>
                  <Button
                    color="secondary"
                    component="a"
                    onClick={() => navigate('/login')}
                    size="small"
                    target="_self"
                    variant="contained"
                  >
                    Anmelden
                  </Button>
                </Box>
                <Box sx={{ float: 'left' }}>
                  <Divider
                    orientation="vertical"
                    sx={{
                      height: 32,
                      mx: 2,
                      ...(hiddenSmDown && { mx: 1 })
                    }}
                  />
                </Box>
                <Box sx={{ float: 'left', ...(hiddenSmDown && { pr: 1 }) }}>
                  <Button
                    color="primary"
                    component="a"
                    onClick={() => navigate('/register')}
                    size="small"
                    target="_self"
                    variant="contained"
                  >
                    Jetzt mitmachen
                  </Button>
                </Box>
              </div>
            )}
            { /* </Hidden> */ }
          </div>
          )}
          {auth.isAuthenticated && (
          <Stack
            direction="row"
            spacing={2}
          >
            <NotificationsPopover />
            {/* <AccountPopover /> */}
            <IconButton
              color="inherit"
              onClick={onSidebarMobileOpen}
            >
              <MenuIcon fontSize="small" />
            </IconButton>
          </Stack>
          )}
        </>
      </Toolbar>
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default MainNavbar;
