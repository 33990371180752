const countries = [
  {
    id: 'AT',
    name: 'Österreich'
  },
  {
    id: 'DE',
    name: 'Deutschland'
  },
  {
    id: 'CH',
    name: 'Schweiz'
  }
];

export default countries;
