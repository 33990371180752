/* eslint-disable no-underscore-dangle */
import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import {
  ImageList,
  ImageListItem,
  Theme,
  useMediaQuery,
  IconButton,
  Box
} from '@mui/material';
import OrgCard from 'src/components/search/OrgCard';
import { OrganizationSearchResponse } from 'src/api/dto/search/OrganizationSearchResponse';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useWindowSize from 'src/hooks/useWindowSize';

interface NewcomerSliderProps {
  organizations: OrganizationSearchResponse;
}

const NewcomerSlider: FC<NewcomerSliderProps> = (props) => {
  const { organizations } = props;

  const ref = useRef(null);
  const windowSize = useWindowSize();
  const breakpointSXOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const [rightPos, setRightPos] = useState(0);
  const [showNavArrows, setShowNavArrows] = useState(true);

  const scrollStep = 260;

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  useEffect(() => {
    if (ref) {
      const contentWidth = ref.current.clientWidth + ref.current.offsetLeft - 52;
      setRightPos(contentWidth);
      setShowNavArrows(ref.current.clientWidth < ref.current.scrollWidth);
    }
  }, [windowSize]);

  if (!organizations) {
    return null;
  }

  return (
    <>
      <ImageList
        sx={{ flexWrap: 'nowrap',
          display: 'flex',
          flexDirection: 'row',
          scrollBehavior: 'smooth'
        }}
        ref={ref}
      >
        {!breakpointSXOnly && showNavArrows && (
        <Box sx={{ position: 'absolute', zIndex: 1020, marginTop: 17 }}>
          <IconButton
            sx={{
              width: 50,
              height: 50,
              background: (theme: Theme) => theme.palette.secondary.main,
              opacity: 0.8,
              '&:hover': {
                backgroundColor: (theme: Theme) => theme.palette.primary.main,
                opacity: 1
              }
            }}
            onClick={() => scroll(-scrollStep)}
          >
            &nbsp;
            <ArrowBackIosIcon sx={{ color: '#fff' }} />
          </IconButton>
        </Box>
        )}
        {organizations.hits.hits.map((org) => (
          <ImageListItem
            key={org._id}
            sx={{ paddingLeft: '5px', paddingRight: '5px', pb: 2 }}
          >
            <OrgCard
              key={org._id}
              orgCardSearch={org._source}
            />
          </ImageListItem>
        ))}
        {!breakpointSXOnly && showNavArrows && (
          <Box sx={{ position: 'absolute', zIndex: 1020, marginTop: 17, left: rightPos }}>
            <IconButton
              sx={{
                width: 50,
                height: 50,
                background: (theme: Theme) => theme.palette.secondary.main,
                opacity: 0.8,
                '&:hover': {
                  backgroundColor: (theme: Theme) => theme.palette.primary.main,
                  opacity: 1
                }
              }}
              onClick={() => scroll(scrollStep)}
            >
              <ArrowForwardIosIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Box>
        )}
      </ImageList>
    </>
  );
};

export default NewcomerSlider;
