import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import {
  Typography,
  ImageList,
  ImageListItem,
  Box,
  Stack,
  Theme,
  useMediaQuery,
  Button,
} from '@mui/material';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import axios from 'src/lib/axios';
import { OrganizationDTO } from 'src/api/dto/organization/organization';
import { AxiosResponse } from 'axios';
import OrgCard from 'src/components/search/OrgCard';
import { Link as RouterLink } from 'react-router-dom';

const Favorites: FC = () => {
  const isMountedRef = useIsMountedRef();
  const [organizations, setOrganizations] = useState<OrganizationDTO[] | null>(null);
  const breakpointSXOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  const getFavorites = useCallback(async () => {
    try {
      const response = await axios.get<number[]>(
        '/api/user/favourites'
      );
      if (isMountedRef.current) {
        const results: Promise<AxiosResponse<OrganizationDTO, any>>[] = [];

        response.data.forEach((organizationNumber) => {
          results.push(axios.get<OrganizationDTO>(
            `/api/organization/${organizationNumber}`
          ));
        });

        const responses = await Promise.all(results);
        const orgs: OrganizationDTO[] = responses.map((x) => x.data);

        setOrganizations(orgs);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getFavorites();
  }, [getFavorites]);

  const getColumns = () => {
    if (breakpointSXOnly) {
      return 1;
    }

    return 2;
  };

  const getMaxItems = () => {
    if (breakpointSXOnly) { return organizations.length; }

    return 2;
  };

  if (!organizations) {
    return null;
  }

  return (
    <Box
      sx={{ pt: 2, pl: 1, ...(breakpointSXOnly && { pl: 0 }) }}
    >
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flex: '50%' }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ pl: 1 }}
          >
            Deine Favoriten
          </Typography>
        </Box>
        {!breakpointSXOnly && organizations.length > getMaxItems() && (
        <Box
          sx={{
            float: 'right'
          }}
        >
          <Button
            sx={{ borderRadius: 5, mt: -0.25 }}
            component={RouterLink}
            to="/favorites"
          >
            Alle Favoriten anzeigen
          </Button>
        </Box>
        )}
      </Box>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={1}
      >
        {organizations.length === 0 && (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ pl: 1 }}>
            <img
              alt="Favorite"
              width="30"
              src="static/icons/KumaoLike_2.svg"
              style={{ marginTop: 3 }}
            />
          </Box>
          <Box sx={{ pl: 1 }}>
            <Typography>
              Du hast noch keinen Dienstleiter zu deinen Favoriten hinzugefügt.
            </Typography>
          </Box>
        </Box>
        )}
        <ImageList
          sx={{ pl: 1, width: '100%', ...(breakpointSXOnly && { display: 'flex' }), ...(!breakpointSXOnly && { alignContent: 'center', justifyContent: 'center' }) }}
          cols={getColumns()}
          gap={10}
        >
          {organizations.slice(0, getMaxItems()).map((org) => (
            <ImageListItem
              key={org.organizationNr}
            >
              <OrgCard
                orgCardDTO={org}
                typ="fav"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Stack>
    </Box>
  );
};

export default Favorites;
