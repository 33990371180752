import { FC, ReactNode } from 'react';
import {
  Box,
  IconButton,
  Theme
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { SxProps } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

export interface DialogBaseProps {
  open: boolean;
  onClose?: () => void;
  children: ReactNode;
  sx?: SxProps<Theme>;
  disableBlur?: boolean;
  enableCloseByBackdropClick?: boolean;
  backgroundColor?: string;
  showCloseIcon?: boolean;
}

const DialogBase: FC<DialogBaseProps> = (props) => {
  const { onClose, children, sx, open, disableBlur, enableCloseByBackdropClick, backgroundColor, showCloseIcon } = props;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'backdropClick') {
      if (!enableCloseByBackdropClick) { return; }
    }

    if (onClose) {
      onClose();
    }
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
      sx={{ ...sx, ...(disableBlur ? { backdropFilter: 'none' } : { backdropFilter: 'blur(2px)' }), '& .MuiDialog-paper': { background: 'transparent', borderRadius: 5 } }}
    >
      {showCloseIcon && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#ffffff',
            zIndex: 999,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <Box
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backdropFilter: 'blur(9px)',
          background: backgroundColor,
          borderRadius: 5,
        }}
      >
        {children}
      </Box>
    </Dialog>
  );
};

DialogBase.defaultProps = {
  backgroundColor: 'rgba(128, 165, 27, 0.5)',
};

export default DialogBase;
