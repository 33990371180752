import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import {
  Container,
  Typography,
  Stack,
  Theme,
  useMediaQuery,
  Box
} from '@mui/material';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { BrancheListDTO } from 'src/api/dto/organization/brancheList';
import axios from 'src/lib/axios';
import { Branche } from 'src/types/branche';
import { branches } from 'src/dummydata/branches';
import useSettings from 'src/hooks/useSettings';
import CategoriesSlider from './CategoriesSlider';
// import { KumaoTheme } from 'src/theme';

const Categories: FC = () => {
  const isMountedRef = useIsMountedRef();
  const { settings } = useSettings();
  const [listBranchen, setListBranchen] = useState<Branche[] | null>(
    null
  );
  const breakpointSXOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  let brancheResponse: Branche[];
  const getListBranchen = useCallback(async () => {
    try {
      const response = await axios.get<BrancheListDTO[]>(
        '/api/organization/listofbranches'
      );
      if (isMountedRef.current) {
        brancheResponse = response.data;
        brancheResponse.forEach((item, index) => {
          const branch = branches.find((x) => x.id === item.id);
          if (branch) {
            brancheResponse[index].color = branch.color;
            brancheResponse[index].left = branch.left;
            brancheResponse[index].name2pl = branch.name2pl;
            brancheResponse[index].picture = branch.picture;
            brancheResponse[index].rotate = branch.rotate;
            brancheResponse[index].top = branch.top;
            brancheResponse[index].name = branch.name;
            brancheResponse[index].smallFont = branch.smallFont;
          } else {
            console.warn(`Branch with id ${item.id}not found.`);
          }
        });
        setListBranchen(brancheResponse);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getListBranchen();
  }, [getListBranchen]);

  if (!listBranchen) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      // backgroundColor: (theme: KumaoTheme) => theme.palette.background.mintgreen
    }}
    >
      <Container
        maxWidth={settings.compact ? 'xl' : false}
        sx={{ paddingTop: 5, paddingBottom: 5, paddingRight: 0, paddingLeft: 0 }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
        >
          <Box sx={{ ...(!breakpointSXOnly && { width: 220 }), paddingLeft: 1, paddingRight: 1 }}>
            <Typography
              variant="h4"
            >
              Durchstöbere unsere Kategorien
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: 'normal', paddingTop: 2, paddingBottom: 2 }}
            >
              und finde Dienstleister ganz in deiner Nähe
            </Typography>
            {/* / <Button variant="contained">alle Kategorien anzeigen</Button> */ }
          </Box>
          <CategoriesSlider listBranches={listBranchen} />
        </Stack>
      </Container>
    </Box>
  );
};

export default Categories;
