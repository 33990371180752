import { FC } from 'react';
import {
  Box,
  LinearProgress,
} from '@mui/material';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material';

interface LoadingProps {
  sx?: SxProps<Theme>;
}

const Loading: FC<LoadingProps> = (props) => {
  const { sx } = props;

  return (
    <Box sx={{ ...sx, width: '100%', position: 'absolute' }}>
      <LinearProgress sx={{ height: 6 }} />
    </Box>
  );
};

export default Loading;
