import { Paper, Typography, Theme, Button, Card, CardContent, Box, Stack, Link } from '@mui/material';
import { FC, useState } from 'react';
import { SxProps } from '@mui/system';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { OrganizationDTO } from 'src/api/dto/organization/organization';
import { Link as RouterLink } from 'react-router-dom';
import makeStringUrlFriendly from 'src/utils/makeStringUrlFriendly';
import DeleteIcon from '@mui/icons-material/Delete';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import CloseIcon from '@mui/icons-material/Close';
import findBranchColor from 'src/utils/findBranchColor';
import * as config from 'src/config';
import { OfferedServiceDTO } from 'src/api/dto/organization/offeredService';
import axios from 'src/lib/axios';
import useSession from 'src/hooks/useSession';
import { styled } from '@mui/material/styles';
import DialogMultipleOptions from 'src/components/widgets/overlays/DialogMultipleOptions';
import { Category } from 'src/api/dto/calender/enCategory';

interface BookingItemProps {
  organization: OrganizationDTO;
  description: string;
  start: Date;
  end: Date;
  isPast?: boolean;
  sx?: SxProps<Theme>;
  onDelete?: (removeFromCourse:boolean) => void;
  calendarGuid: string;
  serviceId: number;
  eventType: Category;
  onlineMeetingLink?: string;
}

const StyledButton = styled(Button)(() => ({
  borderRadius: 20,
}));

const BookingItem: FC<BookingItemProps> = (props) => {
  const { sx, organization, description, start, end, isPast, onDelete, calendarGuid, serviceId, eventType, onlineMeetingLink } = props;
  const [showDialog, setShowDialog] = useState(false);
  const { session, addService } = useSession();

  const handleAddService2Cart = async () => {
    try {
      const response = await axios.get<OfferedServiceDTO>(
        '/api/offeredservice', { params: { organizationNr: organization.organizationNr, serviceId } }
      );

      const service = response.data;
      const serviceDto: OfferedServiceDTO = {
        serviceID: service.serviceID,
        organizationNr: service.organizationNr,
        description: service.description,
        htmlContent: service.htmlContent,
        currency: service.currency,
        price: service.price,
        timeFrom: '',
        timeTo: '',
        duration: service.duration,
        imagePath: service.imagePath,
        followUpTime: service.followUpTime,
        sortOrder: 1,
      };
      addService(session, serviceDto, null);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDialog = (result: string) => {
    if (result === 'del') {
      if (onDelete) { onDelete(false); }
    }
    if (result === 'delCourse') {
      if (onDelete) { onDelete(true); }
    }
    setShowDialog(false);
  };

  const handleDeleteBooking = () => {
    setShowDialog(true);
  };

  const getDialogOptions = () => {
    if (eventType === Category.Course) {
      return ([
        { text: 'Nur diesen Termin löschen', key: 'del', icon: <EventBusyIcon /> },
        { text: 'Vom Kurs abmelden', key: 'delCourse', icon: <DeleteIcon /> },
        { text: 'Nein', key: '', icon: <CloseIcon /> }
      ]);
    }
    return ([
      { text: 'Ja', key: 'del', icon: <EventBusyIcon /> },
      { text: 'Nein', key: '', icon: <CloseIcon /> }
    ]);
  };

  return (
    <>
      <Paper
        sx={{ ...sx, borderRadius: 5, minHeight: 100 }}
        elevation={1}
      >
        <Card sx={{ display: 'flex', p: 0, borderRadius: 5 }}>
          <Box
            sx={{ ...(isPast && { backgroundColor: 'white', opacity: 0.8 }) }}
          >
            <Box
              sx={{ height: '100%', width: 20, backgroundColor: findBranchColor(organization.branches) }}
            />
          </Box>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            sx={{ width: '100%' }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...(isPast && { backgroundColor: 'white', opacity: 0.8 }) }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Link
                  color="text.primary"
                  component={RouterLink}
                  to={`/${makeStringUrlFriendly(organization.companyName)}-${organization.organizationNr}`}
                  underline="none"
                  variant="h3"
                >
                  {organization.companyName}
                </Link>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  {description}
                </Typography>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  component="div"
                >
                  {start.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  {start.toLocaleTimeString('de-DE', { timeStyle: 'short' })}
                  {' - '}
                  {end.toLocaleTimeString('de-DE', { timeStyle: 'short' })}
                  {' '}
                  Uhr
                </Typography>
                {onlineMeetingLink && (
                  <Stack direction="row">
                    <Typography
                      color="text.secondary"
                      variant="subtitle1"
                    >
                      Findet online statt:
                    </Typography>
                    <Link
                      href={onlineMeetingLink}
                      underline="hover"
                      target="_blank"
                      rel="noopener"
                      sx={{ pl: 1 }}
                    >
                      Online Meeting Link aufrufen
                    </Link>
                  </Stack>
                )}
              </CardContent>
            </Box>
            <Box sx={{ display: 'flex', pl: 1, pb: 2, pr: 2, justifyContent: 'center', ...(isPast && { width: 260 }) }}>
              {!isPast && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <StyledButton
                    variant="outlined"
                    aria-label="Termin herunterladen"
                    href={`${config.serverConfig.baseAPIUrl}/api/calendar/mycalendarentry/download/${calendarGuid}`}
                    startIcon={<EventNoteIcon />}
                  >
                    Herunterladen
                  </StyledButton>
                  <StyledButton
                    aria-label="Termin löschen"
                    onClick={handleDeleteBooking}
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                  >
                    Stornieren
                  </StyledButton>
                </Stack>
              )}
              {isPast && (
                <Link
                  component={RouterLink}
                  to={`/${makeStringUrlFriendly(organization.companyName)}-${organization.organizationNr}/booking`}
                  underline="none"
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <StyledButton
                    variant="outlined"
                    aria-label="Erneut buchen"
                    sx={{ ml: 5 }}
                    onClick={handleAddService2Cart}
                  >
                    Erneut buchen
                  </StyledButton>
                </Link>
              )}
            </Box>
          </Stack>
        </Card>
      </Paper>
      <DialogMultipleOptions
        open={showDialog}
        title={`Willst du deinen Termin bei ${organization.companyName} wirklich stornieren?`}
        onClose={handleDialog}
        buttons={getDialogOptions()}
      />

    </>
  );
};

export default BookingItem;
