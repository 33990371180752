import { FC, useEffect, useState, useCallback } from 'react';
import {
  Avatar,
  Box, MenuItem, TextField, Typography
} from '@mui/material';
import axios from 'src/lib/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { serverConfig } from 'src/config';
import { BookingUserDTO } from 'src/api/dto/booking/bookingUser';
import { KumaoTheme } from 'src/theme';

export interface UserSelectComboBoxProps {
  serviceIDs: number[];
  selectedUser: BookingUserDTO;
  organizationNr: number;
  onChange: (user: BookingUserDTO) => void;
}

const UserSelectComboBox: FC<UserSelectComboBoxProps> = (props) => {
  const { serviceIDs, selectedUser, organizationNr, onChange } = props;
  const isMountedRef = useIsMountedRef();
  const [users, setUsers] = useState<BookingUserDTO[]>(null);

  const loadUsers = useCallback(async (_serviceIDs: number[]) => {
    try {
      // TODO Check why it is called so often on the booking page
      const response = await axios.post<BookingUserDTO[]>('/api/offeredservices/assignedemployeesservicelist',
        { organizationnr: organizationNr,
          serviceidlist: _serviceIDs });
      if (isMountedRef.current) {
        setUsers(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    loadUsers(serviceIDs);
  }, [isMountedRef]);

  const handleChange = (userID: string) => {
    let user = users.find((item) => item.userId === userID);
    if (user === undefined) {
      user = null;
    }
    if (onChange) {
      onChange(user);
    }
  };

  useEffect(() => {
    // preselect user if only one choice
    if (users !== null && users !== undefined && users.length === 1 && (selectedUser === undefined || selectedUser === null)) {
      handleChange(users[0].userId);
    }
  }, [users]);

  if (users === null || users === undefined) return null;

  const getSelectedUserID = (user: BookingUserDTO) => {
    if (user === undefined || user === null) {
      return 0;
    }
    return user.userId;
  };

  return (
    <TextField
      fullWidth
      label="Mitarbeiter*in"
      margin="normal"
      name="user"
      select
      value={getSelectedUserID(selectedUser)}
      onChange={(item) => handleChange(item.target.value)}
      variant="outlined"
    >
      { users.length > 1 && (
      <MenuItem
        key={0}
        value={0}
      >
        <Box
          component="div"
          alignItems="center"
          display="flex"
        >
          <Box
            component="span"
            ml={1}
          >
            *Überrasche mich*
          </Box>

        </Box>
      </MenuItem>
      )}
      {users.map((user) => (
        <MenuItem
          key={user.userId}
          value={user.userId}
        >
          <Box
            component="div"
            alignItems="center"
            display="flex"
          >
            <Avatar
              src={user.avatar ? serverConfig.baseIMGUrl + user.avatar : ''}
              sx={{
                height: 32,
                width: 32,
                marginTop: 0.1,
                bgcolor: user.avatar ? '' : (theme: KumaoTheme) => theme.palette.primary.light
              }}
            >
              {!user.avatar && (
              <Typography
                color="textPrimary"
                fontWeight={700}
              >
                {user.firstName && user.firstName.length > 0 ? user.firstName[0].toUpperCase() : ''}
                {user.lastName && user.lastName.length > 0 ? user.lastName[0].toUpperCase() : ''}
              </Typography>
              )}
            </Avatar>
            <Box
              component="span"
              ml={1}
            >
              {user.firstName}
            </Box>

          </Box>
        </MenuItem>
      ))}
    </TextField>
  );
};

export default UserSelectComboBox;
