/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */
import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import {
  Container,
  Switch,
  TextField,
  Typography,
  styled,
  Grid,
  Box
} from '@mui/material';
import useDebounce from 'src/hooks/useDebounce';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import geoSearch from 'src/utils/geoSearch';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { OrganizationSearchResponse } from 'src/api/dto/search/OrganizationSearchResponse';
import axios from 'axios';
import { serverConfig } from 'src/config';
import useSettings from 'src/hooks/useSettings';
import OrgCard from 'src/components/search/OrgCard';
import useSession from 'src/hooks/useSession';

// #region Helper component for map
interface MyMapHelperProps {
  position: L.LatLngExpression;
  zoom: number;
  bounds: L.LatLngBoundsExpression;
  boundsAction: Boolean;
}

const MyMapHelper: FC<MyMapHelperProps> = (props) => {
  const { position, zoom, bounds, boundsAction } = props;
  const map = useMap();
  map.setView(position, zoom);
  if (boundsAction) {
    map.fitBounds(bounds);
  }
  return null;
};

// #endregion

const StyledPopup = styled(Popup)`
  .leaflet-popup-content p {
    margin: 0;
  }
  .leaflet-popup-content-wrapper, .leaflet-popup-tip {
    background: rgba(255,255,255,0.2);
  }
  a.leaflet-popup-close-button {
    color: rgb(0,0,0) !important;
  }
`;

interface SimpleProps {
  organizationsFilter?: OrganizationSearchResponse;
}

const Explore: FC<SimpleProps> = (simpleProps) => {
  // const icon = L.icon({ iconUrl: '/static/home/marker-icon2.png' });
  const { session } = useSession();
  const { organizationsFilter } = simpleProps;
  const iconMe = L.icon({ iconUrl: '/static/home/home.png', iconSize: [40, 40] });
  const [state, setState] = useState({
    checkedActualLocation: true,
    position: [48.15426, 14.81037] as L.LatLngExpression,
    searchTerm: '',
    zoom: 11,
    locationLabel: ''
  });
  const [bounds, setBounds] = useState<L.LatLngBoundsExpression>([[48.15426, 14.81037]]);
  const [boundsAction, setBoundsAction] = useState<Boolean>(false);

  const { settings } = useSettings();
  const isMountedRef = useIsMountedRef();
  const [organizations, setOrganizations] = useState<OrganizationSearchResponse | null>(
    null
  );

  const getOrganizations = useCallback(async () => {
    const query = {
      query: {
        match_all: {}
      }
    };
    try {
      const instance = axios.create();
      delete instance.defaults.headers.common.Authorization;
      instance.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
      const response = await instance.post<OrganizationSearchResponse>(
        serverConfig.searchUrl, JSON.stringify(query), {
          auth: {
            username: 'readall',
            password: 'readonly'
          } }
      );
      if (isMountedRef.current) {
        console.group('SEARCH');
        console.log(response.data);
        console.groupEnd();
        response.data.hits.hits.map((searchObj, i) => (
          response.data.hits.hits[i]._source.branches = JSON.parse(searchObj._source.branchesjson)
        ));
        setOrganizations(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const handleChange = (event) => {
    setBoundsAction(false);
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const setGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((geoPosition) => {
        if (isMountedRef.current) {
          setState({
            ...state,
            position: [geoPosition.coords.latitude, geoPosition.coords.longitude],
            searchTerm: '',
            locationLabel: 'Deine Position'
          });
        } else {
          console.warn('Explore component is not mounted anymore.');
        }
      });
    } else {
      console.warn('Geolocation is not supported by this browser.');
    }
  };

  const debouncedSearchTerm = useDebounce(state.searchTerm, 800);
  useEffect(
    () => {
      // Make sure we have a value (user has entered something in input)
      if (state.searchTerm) {
        setBoundsAction(false);
        geoSearch(state.searchTerm).then((value) => {
          console.log('Locations found', value);
          if (value.length) {
            setState({
              ...state,
              position: [value[0].y, value[0].x],
              checkedActualLocation: false,
              locationLabel: value[0].label,
              zoom: 12
            });
            console.log('Selected location', value[0]);
          }
        });
      }
    },
    // This is the useEffect input array
    // Our useEffect function will only execute if this value changes ...
    // ... and thanks to our hook it will only change if the original ...
    // value (searchTerm) hasn't changed for more than 500ms.
    [debouncedSearchTerm]
  );

  useEffect(() => {
    if (state.checkedActualLocation) {
      setGeoLocation();
    }
  }, [state.checkedActualLocation]);

  useEffect(() => {
    console.log('useEffect');
    if (organizationsFilter !== null) {
      if (organizationsFilter.hits.total.value > 0) {
        setOrganizations(organizationsFilter);
      } else {
        getOrganizations();
      }
    } else {
      getOrganizations();
    }
  }, [organizationsFilter]);

  useEffect(() => {
    const markerArray = [
      [48.15426, 14.81037]
    ];

    if (organizations !== null) {
      markerArray.length = 0;
      organizations.hits.hits.forEach((orgsForMarker) => (
        markerArray.push([orgsForMarker._source.latitude, orgsForMarker._source.longitude])
      ));

      setBoundsAction(true);
      setState({
        ...state,
        checkedActualLocation: false
      });
      setBounds(markerArray as L.LatLngBoundsExpression);
    }
  }, [organizations]);

  useEffect(() => {
    if (session.searchTerm === '' && session.selectedSearchMode === 'Alle') {
      getOrganizations();
    }
  }, [session]);

  if (!organizations) { return null; }

  return (
    <>
      <Container
        maxWidth={settings.compact ? 'xl' : false}
        sx={{ paddingTop: 0, mt: -2, pl: 1, pr: 1 }}
      >
        <TextField
          id="standard-basic"
          label="Postleitzahl"
          value={state.searchTerm}
          onChange={(e) => setState({ ...state, searchTerm: e.target.value })}
          sx={{
            position: 'absolute',
            zIndex: 990,
            marginTop: 2,
            marginLeft: 7,
            width: 120,
            backgroundColor: 'white'
          }}
        />
        <Box sx={{ border: 5, borderColor: '#fff' }}>
          <MapContainer
            scrollWheelZoom={false}
            touchZoom
            dragging={!L.Browser.mobile}
            style={{ height: 400 }}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              position={state.position}
              icon={iconMe}
              zIndexOffset={999}
            >
              <Popup>{state.locationLabel}</Popup>
            </Marker>
            {organizations.hits.hits.map((org) => (
              (org._source.branchesname?.length > 0 && (
              <Marker
                key={org._source.organization_nr}
                zIndexOffset={999}
                position={[org._source.latitude, org._source.longitude]}
                icon={L.icon({ iconUrl: `/static/home/marker-pin_${org._source.branches[0].id}.png`, iconSize: [40, 40] })}
              >
                <StyledPopup sx={{ minWidth: 230 }}>
                  <OrgCard
                    orgCardSearch={org._source}
                    typ="map"
                  />
                </StyledPopup>
              </Marker>
              ))))}
            <MyMapHelper
              position={state.position}
              zoom={state.zoom}
              bounds={bounds}
              boundsAction={boundsAction}
            />
          </MapContainer>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            zIndex: 999,
            marginTop: 0.2,
            paddingLeft: '20px'
          }}
        >
          <Grid sx={{
            paddingTop: 0.6
          }}
          >
            <Typography>
              Aktuellen Standort verwenden
            </Typography>
          </Grid>
          <Grid>
            <Switch
              color="primary"
              checked={state.checkedActualLocation}
              onChange={handleChange}
              name="checkedActualLocation"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
        </Grid>
        {/* <Box mt={3}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={6}
              sm={4}
            >
              <Typography
                id="discrete-slider"
                gutterBottom
              >
                Umkreis
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
            >
              <Stack
                spacing={2}
                direction="row"
                sx={{ mb: 1 }}
                alignItems="center"
              >
                <Slider
                  defaultValue={20}
                  getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={5}
                  marks
                  min={5}
                  max={100}
                  onChange={handleSliderChange}
                />
                <Typography>km</Typography>
              </Stack>
            </Grid>
          </Grid>
          </Box> */}
      </Container>
    </>
  );
};

export default Explore;
