import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CardMedia, Typography, Grid, Divider } from '@mui/material';
import { FC } from 'react';
import { BookingUserDTO } from 'src/api/dto/booking/bookingUser';
import useSession from 'src/hooks/useSession';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SelectedService } from 'src/types/selectedService';
import zeroPad from 'src/utils/zeroPad';
import UserSelectComboBox from './UserSelectComboBox';
import BookingSlotPicker from './BookingSlotPicker';

interface ServiceBookingItemProps {
  organizationNr: number;
  onSelectedSlot(slot:Date):void;
  sessionService: SelectedService;
  id: Number;
  colorCode: string;
  selected: boolean;
  onSelect(id: Number):void;
  sessionServices: SelectedService[];
}

const ServiceBookingItemAccordion: FC<ServiceBookingItemProps> = (props) => {
  const { organizationNr, onSelectedSlot, sessionService, id, selected, onSelect, sessionServices } = props;
  const { session, saveSession } = useSession();

  const removeService = (): void => {
    saveSession({
      ...session,
      selectedServices: session.selectedServices.filter((item) => item.id !== id)
    });
  };

  const handleChangeUser = (user:BookingUserDTO): void => {
    session.selectedServices.find((item) => item.id === id).user = user;
    saveSession({
      ...session
    });
  };

  const handleClick = ():void => {
    console.log('test');
    onSelect(id);
  };

  const selectedUser = ():BookingUserDTO => {
    const serv = session.selectedServices.find((item) => item.id === id);
    if (serv !== null && serv !== undefined) {
      return serv.user;
    }
    return undefined;
  };

  const getDateAndTime = (startDate: Date, duration: number) => {
    const endDate = new Date(startDate);
    endDate.setMinutes(startDate.getMinutes() + duration);
    return (
      `am ${startDate.toLocaleDateString()} 
      von ${zeroPad(startDate.getHours(), 2)}:${zeroPad(startDate.getMinutes(), 2)} 
      bis ${zeroPad(endDate.getHours(), 2)}:${zeroPad(endDate.getMinutes(), 2)}`
    );
  };

  if (sessionService === undefined) return null;

  return (
    <Accordion
      expanded={selected}
      onChange={handleClick}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography
          sx={{ fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto', color: '#4F5A68', letterSpacing: 0.15, width: '50%' }}
          variant="body2"
          gutterBottom
          component="div"
        >
          {`${sessionService.service.description}`}
        </Typography>
        {sessionService.selectedSlot
          ? (
            <Typography
              sx={{ fontWeight: 'normal', fontSize: '16px', fontFamily: 'Roboto', color: '#4F5A68', letterSpacing: 0.15, pl: 1, justifyContent: 'center', width: '50%' }}
              variant="body2"
              gutterBottom
              component="div"
            >
              {getDateAndTime(sessionService.selectedSlot, sessionService.service.duration)}
            </Typography>
          ) : (
            <Typography
              color="error"
              sx={{ fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto', letterSpacing: 0.15, pl: 1, width: '50%' }}
              variant="body2"
              gutterBottom
              component="div"
            >
              Bitte einen Termin auswählen
            </Typography>
          )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          sx={{ mb: 2 }}
        >
          <Grid
            item
            xs={6}
          >
            <CardMedia
              component="img"
              alt={sessionService.service.description}
              sx={{ width: 134, height: 99, float: 'right', borderRadius: '20px', mr: 1 }}
              image={sessionService.service.imagePath}
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: 'flex', alignItems: 'left', float: 'left' }}
          >
            <Box sx={{ margin: 'auto', float: 'left', width: '100%', ml: 1 }}>
              <Typography
                sx={{ fontWeight: '600', fontFamily: 'Roboto', color: '#4F5A68', letterSpacing: 0.15 }}
                variant="body2"
                gutterBottom
                component="div"
              >
                {`Dauer: ${sessionService.service.duration} min`}
              </Typography>
              <Typography
                sx={{ fontWeight: '600', fontFamily: 'Roboto', color: '#4F5A68', letterSpacing: 0 }}
                variant="body2"
                gutterBottom
                component="div"
              >
                {`Preis: ${sessionService.service.price} €`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ float: 'right' }}>
          <Button
            fullWidth
            color="primary"
            size="large"
            sx={{ borderRadius: '20px', borderWidth: '2px', fontFamily: 'Roboto' }}
            variant="text" // contained
            onClick={removeService}
          >
            Service entfernen
          </Button>
        </Box>
        <UserSelectComboBox
          // serviceID={service.serviceID}
          serviceIDs={[sessionService.service.serviceID]}
          organizationNr={sessionService.organizationNr}
          selectedUser={selectedUser()}
          onChange={handleChangeUser}
        />
        <Divider sx={{ mt: 2, mb: 2 }} />
        <BookingSlotPicker
          service={sessionService}
          organizationNr={organizationNr}
          onSelectedSlot={onSelectedSlot}
          selectedDate={sessionService.selectedSlot}
          sessionServices={sessionServices}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ServiceBookingItemAccordion;
