import { Button, Card, CardActions, CardContent, CardMedia, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { BookingUserDTO } from 'src/api/dto/booking/bookingUser';
import useSession from 'src/hooks/useSession';
import { SelectedService } from 'src/types/selectedService';
import zeroPad from 'src/utils/zeroPad';
import UserSelectComboBox from './UserSelectComboBox';

interface ServiceBookingItemProps {
  sessionService: SelectedService;
  id: Number;
  colorCode: string;
  selected: Boolean;
  onSelect(id: Number):void;
}

const ServiceBookingItem: FC<ServiceBookingItemProps> = (props) => {
  const { sessionService, id, selected, onSelect } = props;
  const { session, saveSession } = useSession();
  const myTheme = useTheme();

  const removeService = (): void => {
    saveSession({
      ...session,
      selectedServices: session.selectedServices.filter((item) => item.id !== id)
    });
  };

  const handleChange = (user:BookingUserDTO): void => {
    session.selectedServices.find((item) => item.id === id).user = user;
    saveSession({
      ...session
    });
  };

  const handleClick = ():void => {
    onSelect(id);
  };

  const selectedUser = ():BookingUserDTO => {
    const serv = session.selectedServices.find((item) => item.id === id);
    if (serv !== null && serv !== undefined) {
      return serv.user;
    }
    return undefined;
  };

  const borderStyle = () => {
    if (selected) {
      return `1px solid ${myTheme.palette.primary.main}`;
    }
    return '';
  };

  const getDateAndTime = (startDate: Date, duration: number) => {
    const endDate = new Date(startDate);
    endDate.setMinutes(startDate.getMinutes() + duration);
    return (
      `am ${startDate.toLocaleDateString()} 
      von ${zeroPad(startDate.getHours(), 2)}:${zeroPad(startDate.getMinutes(), 2)} 
      bis ${zeroPad(endDate.getHours(), 2)}:${zeroPad(endDate.getMinutes(), 2)}`
    );
  };

  if (sessionService === undefined) return null;

  return (
    <Card
      elevation={3}
      sx={{ p: 1, borderRadius: '20px', boxShadow: '0px -5px 18px rgba(0, 0, 0, 0.1)', border: borderStyle() }}
      onClick={handleClick}
    >
      <CardMedia
        component="img"
        alt={sessionService.service.description}
        sx={{ width: 134, height: 99, float: 'left', borderRadius: '20px' }}
        image={sessionService.service.imagePath}
      />
      <CardContent sx={{ float: 'left', p: 1, ml: 1 }}>
        <Typography
          sx={{ fontWeight: 'bold', fontSize: '16px', fontFamily: 'Roboto', color: '#4F5A68', letterSpacing: 0.15 }}
          variant="body2"
          gutterBottom
          component="div"
        >
          {sessionService.service.description}
        </Typography>
        {sessionService.selectedSlot
          ? (
            <Typography
              sx={{ fontWeight: '600', fontFamily: 'Roboto', color: '#4F5A68', letterSpacing: 0.15 }}
              variant="body2"
              gutterBottom
              component="div"
            >
              {getDateAndTime(sessionService.selectedSlot, sessionService.service.duration)}
            </Typography>
          ) : (
            <Typography
              color="error"
              sx={{ fontWeight: '600', fontFamily: 'Roboto', letterSpacing: 0.15 }}
              variant="body2"
              gutterBottom
              component="div"
            >
              Bitte einen Termin auswählen
            </Typography>
          )}
        <Typography
          sx={{ fontWeight: '600', fontFamily: 'Roboto', color: '#4F5A68', letterSpacing: 0.15 }}
          variant="body2"
          gutterBottom
          component="div"
        >
          {`${sessionService.service.duration} min | ${sessionService.service.price} €`}
        </Typography>
        <UserSelectComboBox
          // serviceID={service.serviceID}
          serviceIDs={[sessionService.service.serviceID]}
          organizationNr={sessionService.organizationNr}
          selectedUser={selectedUser()}
          onChange={handleChange}
        />
      </CardContent>
      <CardActions sx={{
        float: 'right',
        width: '150px',
        height: '99px',
        justifyContent: 'center',
        alignContent: 'center',
        mr: 0 }}
      >
        <Button
          color="primary"
          size="large"
          sx={{ borderRadius: '20px', borderWidth: '2px', fontFamily: 'Roboto' }}
          variant="text" // contained
          onClick={removeService}
        >
          Entfernen
        </Button>
      </CardActions>
    </Card>
  );
};

export default ServiceBookingItem;
