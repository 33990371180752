import { FC } from 'react';
import {
  Button,
  CircularProgress,
  Link,
  Typography
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import DialogBase from './DialogBase';

export interface WaitDialogProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  text?: string;
  buttonText?: string;
  link?: string;
  showButton?: boolean;
}

const WaitDialog: FC<WaitDialogProps> = (props) => {
  const { onClose, open, title, text, buttonText, link, showButton } = props;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'backdropClick') {
      return;
    }

    if (onClose) {
      onClose();
    }
  };
  return (
    <DialogBase
      onClose={handleClose}
      open={open}
    >
      <CircularProgress sx={{ color: '#fff' }} />
      <Typography
        color="#fff"
        variant="h5"
      >
        {title}
      </Typography>
      <Typography
        align="center"
        color="#fff"
        sx={{ mt: 1 }}
        variant="h6"
      >
        {text}
      </Typography>
      {showButton && (
        <Link
          color="primary"
          component={RouterLink}
          to={link}
        >
          <Button
            fullWidth
            onClick={handleClose}
            size="large"
            variant="outlined"
            color="primary"
            sx={{ mt: 4, width: 100, borderRadius: 20, borderWidth: 2, backgroundColor: '#FFFFFF', mb: 2, '&:hover': { borderWidth: 2, backgroundColor: '#efefed' } }}
          >
            {buttonText}
          </Button>
        </Link>
      )}
    </DialogBase>
  );
};

WaitDialog.defaultProps = {
  title: 'Bitte warten...',
  buttonText: 'Abbrechen',
  link: '',
  showButton: true,
};

export default WaitDialog;
