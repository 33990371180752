import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { SearchResult } from 'leaflet-geosearch/dist/providers/provider';

const provider = new OpenStreetMapProvider({
  params: {
    'accept-language': 'de',
    countrycodes: 'at'
  }
});

const geoSearch = async (searchValue: string) => {
  const result = (await provider.search({
    query: searchValue
  })) as SearchResult[];
  return result;
};

export default geoSearch;
