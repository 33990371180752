import { Typography, Pagination, Box } from '@mui/material';
import type { Theme } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { SxProps } from '@mui/system';
import { CalenderEntryDTO } from 'src/api/dto/calender/calenderEntry';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import axios from 'src/lib/axios';
import { OrganizationDTO } from 'src/api/dto/organization/organization';
import SuccessOneButton from 'src/components/widgets/overlays/SuccessOneButton';
import { PagingResult } from 'src/api/dto/common/pagingResponse';
import BookingItem from './BookingItem';

interface BookingsProps {
  showPastBookings?: boolean;
  sx?: SxProps<Theme>;
}

const Bookings: FC<BookingsProps> = (props) => {
  const { sx, showPastBookings } = props;
  const isMountedRef = useIsMountedRef();
  const [calendarEntries, setCalendarEntries] = useState<CalenderEntryDTO[] | null>(null);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [organizations, setOrganizations] = useState<OrganizationDTO[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [page, setPage] = useState(1);

  const pageSize = 2;

  const getOrganization = async (id: number) => {
    try {
      const response = await axios.get<OrganizationDTO>(
        `/api/organization/${id}`
      );

      return response.data;
    } catch (err) {
      console.error(err);
    }

    return null;
  };

  const getCalendarBookings = useCallback(async (fn: string, currentPage: number, currentPageSize: number) => {
    try {
      const response = await axios.get<CalenderEntryDTO[]>(
        `/api/calendar/${fn}/${currentPage - 1}/${currentPageSize}`
      );

      if (isMountedRef.current) {
        setCalendarEntries(response.data);

        const newOrgs = organizations.slice(0);
        // eslint-disable-next-line no-restricted-syntax
        for (const entry of response.data) {
          if (newOrgs.findIndex((x) => x.organizationNr === entry.organizationid) === -1) {
            // eslint-disable-next-line no-await-in-loop
            const org = await getOrganization(entry.organizationid);
            if (org) {
              newOrgs.push(org);
            }
          }
        }

        setOrganizations(newOrgs);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getCalendarBookingsTotalPages = useCallback(async (fn: string, currentPage: number, currentPageSize: number) => {
    try {
      const totalPagesResponse = await axios.get<PagingResult>(
        `/api/calendar/${fn}/totalpages/${currentPage - 1}/${currentPageSize}`
      );

      if (isMountedRef.current) {
        setTotalPages(totalPagesResponse.data.totalPages);
        setTotalElements(totalPagesResponse.data.totalElements);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const deleteBooking = async (calendarId: number, removeFromCourse: boolean) => {
    try {
      if (removeFromCourse) {
        await axios.delete(`/api/calendar/mycalendarbooking/${calendarId}/true`);
      } else {
        await axios.delete(`/api/calendar/mycalendarbooking/${calendarId}/false`);
      }
    } catch (err) {
      console.error(err);
    }

    return true;
  };

  const handleDeleteBooking = async (calendarId: number, removeFromCourse: boolean) => {
    if (await deleteBooking(calendarId, removeFromCourse)) {
      await getCalendarBookingsTotalPages('myopencalendarbookings', page, pageSize);
      getCalendarBookings('myopencalendarbookings', page, pageSize);
      setOpenSuccessDialog(true);
    }
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
  };

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (showPastBookings) {
      getCalendarBookingsTotalPages('mypreviouscalendarbookingscount', page, pageSize);
    } else {
      getCalendarBookingsTotalPages('myopencalendarbookings', page, pageSize);
    }
  }, [isMountedRef, pageSize]);

  useEffect(() => {
    if (showPastBookings) {
      getCalendarBookings('mypreviouscalendarbookings', page, pageSize);
    } else {
      getCalendarBookings('myopencalendarbookings', page, pageSize);
    }
  }, [isMountedRef, page]);

  if (!calendarEntries) return null;

  return (
    <Box sx={{ ...sx }}>
      {calendarEntries.map((calendarEntry) => (
        (organizations.findIndex((x) => x.organizationNr === calendarEntry.organizationid) > -1 && (
          <BookingItem
            key={calendarEntry.id}
            calendarGuid={calendarEntry.calendarGuid}
            sx={{ mt: 2 }}
            organization={organizations.find((x) => x.organizationNr === calendarEntry.organizationid)}
            description={calendarEntry.title}
            start={new Date(calendarEntry.start)}
            end={new Date(calendarEntry.end)}
            onDelete={(removeFromCourse) => handleDeleteBooking(calendarEntry.id, removeFromCourse)}
            isPast={showPastBookings}
            serviceId={calendarEntry.services[0]}
            eventType={calendarEntry.category}
            onlineMeetingLink={calendarEntry.onlineMeetingLink}
          />
        ))
      ))}
      {calendarEntries?.length > 0 && totalElements > 0 && (
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePagingChange}
          sx={{ pt: 2, pb: 1 }}
          boundaryCount={1}
          siblingCount={0}
        />
      )}
      {(!calendarEntries || calendarEntries.length === 0) && (
      <Typography
        variant="body1"
      >
        Du hast noch keine Buchungen
      </Typography>
      )}
      <SuccessOneButton
        open={openSuccessDialog}
        onClose={handleCloseSuccessDialog}
        title="Termin storniert"
        text="Dein Termin wurde erfolgreich storniert."
        buttonText="Ok"
      />
    </Box>
  );
};

export default Bookings;
