import { useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Link,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';
import CustomerBenefits from 'src/components/commons/CustomerBenefits';
import SupplierBenefits from 'src/components/commons/SupplierBenefits';
import useAuth from 'src/hooks/useAuth';
import RegisterJWT from './RegisterJWT';

export interface RegisterBlockProps {
  firstName?: string;
  lastName?: string;
  email?:string;
  pricePackage?: string;
  hideBusinessRegistration?: boolean;
  isBookingSuccessRegister?: boolean;
  disableGutters?: boolean;
}

const RegisterBlock: FC<RegisterBlockProps> = (props) => {
  const { firstName, lastName, email, pricePackage, hideBusinessRegistration, isBookingSuccessRegister, disableGutters } = props;
  const { platform } = useAuth();
  const [supplierBenefitsVisState, setSupplierBenefitsVisState] = useState(!!pricePackage);
  const hiddenMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const toggleSupplierBenefitsVisState = (state: boolean) => {
    setSupplierBenefitsVisState(state);
  };

  return (
    <Container
      maxWidth="lg"
      disableGutters={disableGutters}
    >
      <Grid
        container
        spacing={1}
      >
        {!hiddenMdDown && (
        <Grid
          item
          xs={12}
          md={5}
        >
          <Container>
            {!isBookingSuccessRegister && (
              <>
                <Typography sx={{ fontFamily: 'Poppins', fontSize: 33, fontWeight: 700, mt: 3, color: '#1B1D21' }}>Lieber Kumao Besucher</Typography>
                <Typography sx={{ fontFamily: 'Poppins', fontSize: 56, fontWeight: 700, mt: 1, mb: 5, color: '#1B1D21' }}>Los gehts!</Typography>
              </>
            )}
            <Box sx={{ mb: '10px' }}>
              <CustomerBenefits />
            </Box>
            {supplierBenefitsVisState && (
            <SupplierBenefits />
            )}
          </Container>
        </Grid>
        )}
        <Grid
          item
          xs={12}
          md={7}
        >
          <Container
            maxWidth="sm"
            disableGutters={disableGutters}
          >
            <Card>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: 4
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 0,
                    mt: 2
                  }}
                >
                  <div>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h4"
                      sx={{ fontSize: 24 }}
                    >
                      Erstelle dein Kumao Konto
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      Registriere dich um exklusive Vorteile zu nutzen
                    </Typography>
                  </div>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    mt: 3
                  }}
                >
                  {platform === 'JWT' && (
                  <RegisterJWT
                    toggleBusinessSwitchState={toggleSupplierBenefitsVisState}
                    firstname={firstName}
                    lastname={lastName}
                    email={email}
                    pricePackage={pricePackage}
                    hideBusinessRegistration={hideBusinessRegistration}
                  />
                  )}
                </Box>
                <Divider sx={{ my: 3 }} />
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/login"
                  variant="body2"
                >
                  Ich habe bereits ein Konto!
                </Link>
              </CardContent>
            </Card>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RegisterBlock;
