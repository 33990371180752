import merge from 'lodash/merge';
import {
  createTheme as createMuiTheme, // use it as createMuiTheme for compatibility
  responsiveFontSizes
} from '@mui/material/styles';
import type {
  Direction,
  Palette,
  PaletteOptions,
  Theme,
  ThemeOptions
} from '@mui/material';
import type {
  // Palette as MuiPalette,
  TypeBackground
} from '@mui/material/styles/createPalette';
import { colors } from '@mui/material';
import { THEMES } from '../constants';
import { lightShadows } from './shadows';
import AllerLt from '../fonts/Aller_Lt.ttf';
import AllerBd from '../fonts/Aller_Bd.ttf';
import RobotoLt from '../fonts/roboto/Roboto-Light.ttf';
import RobotoBd from '../fonts/roboto/Roboto-Bold.ttf';
import RubikLt from '../fonts/rubik/Rubik-Light.ttf';
import RubikBd from '../fonts/rubik/Rubik-Bold.ttf';
import PoppinsLt from '../fonts/poppins/Poppins-Light.ttf';
import PoppinsBd from '../fonts/poppins/Poppins-SemiBold.ttf';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}

interface KumaoTypeBackground extends TypeBackground {
  dark: string;
  mintgreen: string;
  light: string;
}

interface KumaoPaletteOptions extends PaletteOptions {
  background?: Partial<KumaoTypeBackground>;
}

interface KumaoThemeOptions extends ThemeOptions {
  palette?: KumaoPaletteOptions;
}

interface KumaoPalette extends Palette {
  background: KumaoTypeBackground;
}

export interface KumaoTheme extends Theme {
  palette: KumaoPalette;
}

declare module '@mui/material/styles' {
  interface Theme {
    colorPool: string[];
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colorPool?: string[];
  }
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Aller';
          font-weight: normal;
          font-style: normal;  
          src: url(${AllerLt}) format('truetype');          
        }
        @font-face {
          font-family: 'Aller';
          font-weight: bold;
          font-style: normal;  
          src: url(${AllerBd}) format('truetype');          
        }
        @font-face {
          font-family: 'Roboto';
          font-weight: normal;
          font-style: normal;  
          src: url(${RobotoLt}) format('truetype');          
        }
        @font-face {
          font-family: 'Roboto';
          font-weight: bold;
          font-style: normal;  
          src: url(${RobotoBd}) format('truetype');          
        }
        @font-face {
          font-family: 'Rubik';
          font-weight: normal;
          font-style: normal;  
          src: url(${RubikLt}) format('truetype');          
        }
        @font-face {
          font-family: 'Rubik';
          font-weight: bold;
          font-style: normal;  
          src: url(${RubikBd}) format('truetype');          
        }
        @font-face {
          font-family: 'Poppins';
          font-weight: normal;
          font-style: normal;  
          src: url(${PoppinsLt}) format('truetype');          
        }
        @font-face {
          font-family: 'Poppins';
          font-weight: bold;
          font-style: normal;  
          src: url(${PoppinsBd}) format('truetype');          
        } 
      `
    }
  },
  typography: {
    button: {
      fontWeight: 600
    },
    fontFamily: 'Aller, Roboto, Rubik, Arial, sans-serif',
    // '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem'
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem'
    },
    h3: {
      fontWeight: 700,
      fontSize: '20px'
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem'
    },
    overline: {
      fontWeight: 600
    }
  }
};

const themesOptions: Record<string, KumaoThemeOptions> = {
  [THEMES.KUMAO]: {
    colorPool: [
      '#aa4c45',
      '#87b4a3',
      '#95a95b',
      '#bb8637',
      '#515f7a',
      '#cfbd53',
      '#7f5f7c',
      '#c57a7b',
      '#703739',
      '#5d755d',
      '#907165',
      '#777a7b'
    ],
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
        mintgreen: '#e8efd6',
        light: '#cbeee3'
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#80a51b'
      },
      secondary: {
        contrastText: '#ffffff',
        main: '#777a7b'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: lightShadows,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
      }
    }
  }
};

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createMuiTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 16
          }
        })
      },
      {
        direction: config.direction
      }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return <KumaoTheme>theme;
};
