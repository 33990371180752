import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import {
  Container,
  Typography,
  Box,
  Stack,
  ImageList,
  ImageListItem,
  Theme,
  useMediaQuery } from '@mui/material';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import axios from 'src/lib/axios';
import { KumaoTheme } from 'src/theme';
import useSettings from 'src/hooks/useSettings';
import findBranchColor from 'src/utils/findBranchColor';
import useSession from 'src/hooks/useSession';
import { AxiosResponse } from 'axios';
import { OrganizationDTO } from 'src/api/dto/organization/organization';
import OrgCard from '../search/OrgCard';

interface MyOrganizationDTO extends OrganizationDTO {
  color: string;
}

const LastViewedOrganizations: FC = () => {
  const isMountedRef = useIsMountedRef();
  const { settings } = useSettings();
  const { session } = useSession();
  const [organizations, setOrganizations] = useState<MyOrganizationDTO[] | null>(null);
  const breakpointSXOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  const getOrganizations = useCallback(async (lastViewed: number[]) => {
    try {
      if (isMountedRef.current) {
        const results: Promise<AxiosResponse<OrganizationDTO, any>>[] = [];

        lastViewed.forEach((organizationNumber) => {
          results.push(axios.get<OrganizationDTO>(
            `/api/organization/${organizationNumber}`
          ));
        });

        const responses = await Promise.all(results);
        const selected: MyOrganizationDTO[] = responses.map((x) => ({ ...x.data, color: findBranchColor(x.data.branches) }));

        setOrganizations(selected);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    if (session && session.lastViewedOrganizations) {
      getOrganizations(session.lastViewedOrganizations);
    }
  }, [getOrganizations]);

  if (!organizations || organizations.length === 0) {
    return null;
  }

  return (
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: (theme: KumaoTheme) => theme.palette.background.mintgreen }}
    >
      <Container
        maxWidth={settings.compact ? 'xl' : false}
        sx={{ paddingTop: 5,
          paddingBottom: 5,
          paddingRight: 0,
          paddingLeft: 0
        }}
      >
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          spacing={2}
        >
          <Typography
            variant="h4"
            sx={{ ...(!breakpointSXOnly && { width: 220 }), paddingLeft: 1, paddingRight: 1 }}
          >
            Deine zuletzt besuchten Dienstleister
          </Typography>
          <ImageList
            sx={{ flexWrap: 'nowrap',
              display: 'flex',
              flexDirection: 'row' }}
          >
            {organizations.map((org) => (
              <ImageListItem
                key={org.organizationNr}
                sx={{ paddingLeft: '5px', paddingRight: '5px' }}
              >
                <OrgCard
                  orgCardDTO={org}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Stack>
      </Container>
    </Box>
  );
};

export default LastViewedOrganizations;
