import type { FC } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { KumaoTheme } from 'src/theme';

const CustomerBenefits: FC = () => (
  <Card
    color="primary"
    sx={{
      flexGrow: 1,
      overflow: 'hidden',
      px: 3,
      pt: 1,
      backgroundColor: (theme: KumaoTheme) => theme.palette.primary.main,
      borderRadius: 5
    }}
  >
    <Box sx={{ my: 1, mx: 'auto', p: 2 }}>
      <Grid
        container
        wrap="nowrap"
        spacing={2}
      >
        <Grid item>
          <AddCircleIcon
            sx={{
              color: (theme: KumaoTheme) => theme.palette.primary.contrastText,
              fontSize: '50px'
            }}
          />
        </Grid>
        <Grid
          item
          xs
          zeroMinWidth
          sx={{ my: 'auto' }}
        >
          <Typography
            sx={{
              color: (theme: KumaoTheme) => theme.palette.primary.contrastText, fontSize: 18, fontWeight: 700
            }}
          >
            In nur wenigen Mausklicks deine Termine buchen
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <Box
      sx={{
        my: 1,
        mx: 'auto',
        p: 2
      }}
    >
      <Grid
        container
        wrap="nowrap"
        spacing={2}
      >
        <Grid item>
          <AddCircleIcon
            sx={{
              color: (theme: KumaoTheme) => theme.palette.primary.contrastText,
              fontSize: '50px'
            }}
          />
        </Grid>
        <Grid
          item
          xs
          sx={{ my: 'auto' }}
        >
          <Typography
            sx={{
              color: (theme: KumaoTheme) => theme.palette.primary.contrastText, fontSize: 18, fontWeight: 700
            }}
          >
            Werde an deine Termine erinnert
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <Box
      sx={{
        my: 1,
        mx: 'auto',
        p: 2,
      }}
    >
      <Grid
        container
        wrap="nowrap"
        spacing={2}
      >
        <Grid item>
          <AddCircleIcon
            sx={{
              color: (theme: KumaoTheme) => theme.palette.primary.contrastText,
              fontSize: '50px'
            }}
          />
        </Grid>
        <Grid
          item
          xs
          sx={{ my: 'auto' }}
        >
          <Typography
            sx={{
              color: (theme: KumaoTheme) => theme.palette.primary.contrastText, fontSize: 18, fontWeight: 700
            }}
          >
            Speichere deine Favoriten und behalte deine Lieblings-Dienstleister im Blick
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </Card>
);

export default CustomerBenefits;
