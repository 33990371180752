import type { FC } from 'react';
import { Typography } from '@mui/material';
import { OfferedEventScheduleDTO } from 'src/api/dto/organization/offeredEventSchedule';
import DateHelper from 'src/utils/dateHelper';

// import PropTypes from 'prop-types';

interface ListAllSchedulesProps {
  schedules: OfferedEventScheduleDTO[];
}

const ListAllSchedules: FC<ListAllSchedulesProps> = (props) => {
  const { schedules } = props;

  return (
    <>
      <Typography
        fontWeight={700}
        fontSize={14}
      >
        Alle Termine:
      </Typography>
      {schedules.map((item) => (
        <Typography
          fontSize={14}
          fontFamily="Rubik"
          key={item.start}
        >
          {`${DateHelper.getWeekDayNameShort(new Date(item.start))} ${new Date(item.start).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })} 
            von ${new Date(item.start).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}
            bis ${new Date(item.end).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}`}
        </Typography>
      ))}
    </>
  );
};

export default ListAllSchedules;
